import React from "react";
import "./kpi.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Kpi = (props) => {
  return (
    <div className="kpi">
      <div className="kpi-inner">
        <span style={{backgroundColor: props.color+'40'}} className="logo-container">
          <FontAwesomeIcon color={props.color} icon={props.icon} className="icon-kpi" />
        </span>
        <div className="text-container">
          <span className="kpi-value">{props.value ?? 0}</span>
          <span className="kpi-title">{props.title || "kpi_title"}</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Kpi);
