import React, { Component, useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import noDataIllu from "../../../assets/images/illustration/no_reserve_data.png";

import "moment/locale/fr";
import CardContract from "./CardsContract/CardContract";
import CardsContractLoader from "./CardsContractLoader/CardsContractLoader";
import Pagination from "./Pagination/Pagination";
import ModalAnswerReserve from "./ModalAnswerReserve/index";
import ModalConsultReserve from "./ModalConsultReserve/index";
import "../../App/App.scss";
import "./Restriction.css";
import { useHistory, useLocation } from "react-router";
import debounce from "lodash.debounce";
import SkeletonLoader from "tiny-skeleton-loader-react";

const Restriction = () => {
  /**
   *
   * states
   *
   */
  const PAGE_LIMIT = 6;
  const PAGE_NEIGHBOURS = 1;
  const BASE_URL = useSelector((state) => state.BASE_URL);

  // UI control state
  const [isLoading, setIsLoading] = useState(false);
  const [modalHandleReserve, setModalHandleReserve] = useState(false);
  const [modalConsultReserve, setModalConsultReserve] = useState(false);
  const [selectedReserve, setSelectedReserve] = useState(null);

  // datas state
  const [reserves, setReserves] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  /**
   *
   * Functions
   *
   */

  const onPageChanged = (data) => {
    const { currentPage, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    getReserves(offset);
  };

  const onHandleReserveClick = (reserve) => {
    setSelectedReserve(reserve);
    setModalHandleReserve(true);
  };

  const onConsultReserveClick = (reserve) => {
    setSelectedReserve(reserve);
    setModalConsultReserve(true);
  };

  const onHandleSearchInput = (event) => {
    const value = event.target.value;
    setSearchInput(value);

    debouncedSearch(searchParams.get("offset"), value);
  };

  const debouncedSearch = useCallback(
    debounce((_offset, _value) => getReserves(_offset, _value), 700),
    [] // will be created only once initially
  );

  const getReserves = useCallback(
    async (offset, query) => {
      if (!BASE_URL) return;

      setIsLoading(true);
      let url = new URL(
        BASE_URL + `/reserves_v2?offset=${offset}&limit=${PAGE_LIMIT}`
      );

      if (query?.length > 0) {
        url = url += `&query=${query}`;
      }

      const token = localStorage.getItem("fleetToken");

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", token);
      let myInit = {
        method: "GET",
        headers: myHeaders,
        cache: "no-cache",
      };

      try {
        const response = await fetch(url, myInit);

        if (response.ok) {
          const result = await response.json();
          if (!result.reservesGroupedByDates) {
            throw new Error("reservesGroupedByDates is not defined");
          }
          setReserves(result);

          const params = new URLSearchParams({ offset: offset });
          if (query?.length > 0) {
            params.append("query", query);
          }
          history.replace({
            pathname: location.pathname,
            search: params.toString(),
          });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    },
    [BASE_URL, history, location.pathname]
  );

  /**
   * Effects
   */

  useEffect(() => {
    getReserves(0, null);
  }, [getReserves]);

  return (
    <>
      <div className="generic-page-wrapper">
        {/* Header */}
        <div className="header-fleet mb-4">
          <div className="header-fleet-inner justify-content-between">
            <div>
              <div className="header-fleet-title">
                <h3>Gestion des réserves</h3>
              </div>

              <h4>Consultez le détail de vos réserves.</h4>
            </div>
            <div>
              <input
                type="text"
                name="price"
                id="price"
                value={searchInput}
                onChange={(e) => onHandleSearchInput(e)}
                className="input-search"
                placeholder="Rechercher par BL"
              />
            </div>
          </div>
        </div>
        {/* Reserves */}
        {reserves?.reservesGroupedByDates &&
        reserves?.reservesGroupedByDates?.length > 0 ? (
          reserves?.reservesGroupedByDates?.map((dateOfReserves) => {
            return (
              <React.Fragment key={`date_${dateOfReserves?.date}`}>
                <div className="mb-3">
                  <span className="list-container-badge">
                    {new Date(dateOfReserves.date).toLocaleDateString("fr-FR", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }) || "Pas d'information de date"}
                  </span>
                </div>
                <div className="list-container mb-5">
                  {dateOfReserves?.reserves.map((reserve, reserveIndex) => {
                    return (
                      <CardContract
                        key={`key_${reserve?.id}`}
                        reserve={reserve}
                        onHandleReserveClick={onHandleReserveClick}
                        onConsultReserveClick={onConsultReserveClick}
                        noBorderBottom={
                          reserveIndex === dateOfReserves?.reserves?.length - 1
                        }
                      />
                    );
                  })}
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <>
            {isLoading ? (
              <>
                <div className="mb-3">
                  <SkeletonLoader height={"18px"} width={"92px"} />
                </div>
                <div className="list-container mb-5">
                  <CardsContractLoader />
                  <CardsContractLoader />
                  <CardsContractLoader />
                  <CardsContractLoader />
                  <CardsContractLoader />
                  <CardsContractLoader noBorderBottom />
                </div>
              </>
            ) : (
              <div className="no-data-wrapper">
                <img src={noDataIllu}></img>
                <p className="title">Aucune réserve</p>
                <p className="desc">
                  Ici s'afficherons les réserves remontées par les utilisateurs
                  sur digitappro{" "}
                </p>
              </div>
            )}
          </>
        )}

        {/* Pagination */}
        <div className="d-flex justify-content-center">
          <Pagination
            totalRecords={reserves?.total}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={PAGE_NEIGHBOURS}
            onPageChanged={onPageChanged}
          />
        </div>
      </div>
      {/* Modals */}
      <ModalAnswerReserve
        show={modalHandleReserve}
        onHide={(isReserveUpdated) => {
          setModalHandleReserve(false);
          setSelectedReserve(null);

          if (isReserveUpdated) {
            getReserves(searchParams.get("offset"));
          }
        }}
        selectedreserve={selectedReserve}
        base_url={BASE_URL}
      />
      <ModalConsultReserve
        show={modalConsultReserve}
        onHide={() => {
          setModalConsultReserve(false);
          setSelectedReserve(null);
        }}
        selectedreserve={selectedReserve}
        base_url={BASE_URL}
      />
    </>
  );
};

export default Restriction;
