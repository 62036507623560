import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faSearch,
  faEdit,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { setVehicles } from "../../../reducer/Action";

import { Container, Row, Col } from "react-bootstrap";

import "../../App/App.scss";
import "./Vehicle.css";
import VehicleItem from "../../VehicleItem/VehicleItem";
import connect from "react-redux/es/connect/connect";
import { Step } from "../../Step/Step";
import MyLoader from "../../Loader/MyLoader";
import { EmptyListComponent } from "../../EmptyListComponent/EmptyListComponent";

export class Vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  getVehicles = () => {
    const url = new URL(this.props.BASE_URL + "/vehicules");
    const token = localStorage.getItem("fleetToken");

    if (this.props.vehicles?.length === 0) {
      this.setState({
        isLoading: true,
      });
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

    console.log("GET request @ ", this.props.BASE_URL + "/vehicules");

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Success") {
              this.setState({
                isLoading: false,
              });
              this.props.setVehicles(result.vehicules);
            }
          });
        } else {
          this.setState({
            isLoading: false,
          });
          console.log("Mauvaise réponse du réseau");
          response.json().then(function (result) {
            console.log("result", result);
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  };

  componentDidMount() {
    this.getVehicles();
  }

  render() {
    if (this.props.vehicles?.length > 0) {
      return (
        <div className="generic-page-wrapper">
          <div className="header-fleet">
            <div className="header-fleet-inner">
              <div>
                <div className="header-fleet-title">
                  <h3>Flotte de véhicule</h3>
                  <span className="tour-header-number badge">
                    {this.props.vehicles ? this.props.vehicles.length : 0}
                  </span>
                </div>

                <h4>
                  Retrouvez la liste de vos véhicules et modifiez leur code pin.
                </h4>
              </div>
              <div>
                <div className={"filter-status-wrapper"}>
                  <div
                    className={"filter-status-wrapper-child"}
                    id={"filter-status-wrapper"}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col>
              <div
                style={{
                  display: !this.state.isLoading ? "block" : "none",
                }}
              >
                {this.props.vehicles.map((value, index) => {
                  return (
                    <VehicleItem
                      key={index}
                      vehicle={value}
                      pinCode={value.pin}
                      BASE_URL={this.props.BASE_URL}
                    />
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
      );
    } else {
      if (!this.state.isLoading) {
        return (
          <div>
            <EmptyListComponent type={"véhicule"} />
          </div>
        );
      } else {
        return (
          <div>
            <div className="header-fleet">
              <div className="header-fleet-inner">
                <div>
                  <div className="header-fleet-title">
                    <h3>Flotte de véhicule</h3>
                    <span className="tour-header-number badge">
                      {this.props.vehicles ? this.props.vehicles.length : 0}
                    </span>
                  </div>

                  <h4>
                    Retrouvez la liste de vos véhicules et modifiez leur code
                    pin.
                  </h4>
                </div>
                <div>
                  <div className={"filter-status-wrapper"}>
                    <div
                      className={"filter-status-wrapper-child"}
                      id={"filter-status-wrapper"}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"loader-section"}>
              <MyLoader />
              <MyLoader />
              <MyLoader />
            </div>
          </div>
        );
      }
    }
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    BASE_URL: state.BASE_URL,
    vehicles: state.vehicles,
  };
};

const mapDispatchToProps = { setVehicles };

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);
