import React from "react";
import Message from "./message";
import "./tchat.scss";

export default function Tchat({ messages }) {
  return (
    <div className="tchat-container">
      {messages.map((message) => {
        return (
          <div key={`key_id_${message.id}`}>
            <Message message={message} />
          </div>
        );
      })}
    </div>
  );
}
