import React from "react"

import './FleetLoader.css'

export function FleetLoader(props){
    return (
        <div className="fleet-generic-floader" style={{display : props.isLoading ? "block" : "none" }}>
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default FleetLoader