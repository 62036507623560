import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

import { Card, Accordion, useAccordionButton } from "react-bootstrap";

import "../App/App.scss";
import earth from "./earth.png";

import StepList from "../StepList/StepList";
import StatusComponent from "../StatusComponent/StatusComponent";
import { useSelector } from "react-redux";

import Skeleton from "react-loading-skeleton";

const ContextAwareToggle = ({
  children,
  eventKey,
  callback,
  callback2,
  activeId,
}) => {
  // const { activeEventKey } = useContext(accordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    if (activeId === eventKey) {
      setTimeout(() => {
        callback && callback(null);
      }, 100);
    } else {
      callback && callback(eventKey);
      callback2 && callback2(eventKey);
    }
  });

  return (
    <div
      as={Card.Header}
      onClick={decoratedOnClick}
      className={"fleet-first-line-tour"}
    >
      {children}
    </div>
  );
};

const TourItem = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const BASE_URL = useSelector((state) => state.BASE_URL);
  const [isOpen, setIsOpen] = useState(false);
  const [localSteps, setLocalSteps] = useState(null);
  const titleRef = useRef();

  useEffect(() => {
    if (props.exactMatch) handleTourCollapse(props.exactMatch);
  }, [props.exactMatch]);

  // This function will fetch
  function handleTourCollapse(id) {
    if (localSteps == null && !isLoading) {
      setIsLoading(true);

      const url = new URL(
        `${BASE_URL}/steps/byTour/${id}${
          props.searchValue ? "?query=" + props.searchValue : ""
        }`
      );
      const token = localStorage.getItem("fleetToken");

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", token);
      let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

      console.log(
        "GET request @ ",
        `${BASE_URL}/steps/byTour/${id}${
          props.searchValue ? "?query=" + props.searchValue : ""
        }`
      );
      fetch(url, myInit)
        .then((response) => {
          if (response.ok) {
            response.json().then((result) => {
              if (result.status === "Success") {
                setLocalSteps(result.steps);
                //props.setStepsForATour(props.tour.tour_id, result.steps);
                setIsLoading(false);
              }
            });
          } else {
            setIsLoading(false);

            console.log("Mauvaise réponse du réseau");
            response.json().then(function (result) {
              console.log("result", result);
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);

          console.log(
            "Il y a eu un problème avec l'opération fetch: " + error.message
          );
        });
    }
  }

  const getCreationDate = (creation_date) => {
    let date = new Date(creation_date)
    return date.getHours().toString().padStart(2, '0')+':'+date.getMinutes().toString().padStart(2, '0')
  }

  return (
    <div>
      <div className={"fleet-item"} ref={titleRef}>
        <ContextAwareToggle
          eventKey={`${props.tour.tour_id}`}
          id={`${props.tour.tour_id}`}
          callback={props.setActiveId}
          callback2={handleTourCollapse}
          activeId={props.activeId}
        >
          <div className={"hw"}>
            <img
              src={earth}
              className={"img-illustration-fleet-2"}
              alt={"truck"}
            />
          </div>
          <div className={"fr"}>
            <span className={"fleet-item-title"}>
              Véhicule {props.tour.vehicule_key || <Skeleton />}{" "}
              <StatusComponent
                key={props.tour.tour_id}
                status={props.tour.status}
                statusType={"tour"}
                isLate={props.tour.is_late}
              />
            </span>
            <span className={"fleet-item-desc"}>
              Tournée n°{props.tour.tour_key || "tour_key"} - importée à {getCreationDate(props.tour.creation_date)}
            </span>
          </div>
          <div className={"hwe"}>
            <button className={"fleet-item-delete"} style={{ display: "none" }}>
              <FontAwesomeIcon icon={faInfo} />
            </button>
          </div>
        </ContextAwareToggle>

        <Accordion.Collapse
          eventKey={`${props.tour.tour_id}`}
          className="fleet-item-collapsed"
        >
          <div className="fleet-item-steps-container">
            <div className="fleet-item-steps-container-inner">
              {true ? (
                <StepList
                  tourId={props?.tour?.tour_id}
                  activeId={props.activeId}
                  isLoading={isLoading}
                  steps={localSteps ? localSteps : []}
                  exactMatch={props.exactMatch}
                  searchValue={props.searchValue}
                />
              ) : null}
            </div>
          </div>
        </Accordion.Collapse>
      </div>
    </div>
  );
};

export default React.memo(TourItem);
