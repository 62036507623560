import React, { Component } from "react";
import axios from "axios";
import { Row, Col, ProgressBar } from "react-bootstrap";
import DragAndDrop from "../../GenericComponent/DragAndDrop/DragAndDrop";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import notebook from "../../../assets/images/isometric/notebook.png";
import "../../App/App.scss";
import "./AddFile.css";

export class AddFile extends Component {
  state = {
    uploadPercentage: 0,
    status: "En attente d'un fichier...",
  };

  uploadFile = (e) => {
    if (e.target.files) {
      this.handleDrop(e.target.files);
      e.target.value = null;
    }
  };

  handleDrop = (files) => {
    for (var i = 0; i < files.length; i++) {
      if (!files[i].name) return;
      console.log("depot du fichier", files[i].name, files);
    }

    // Create an object of formData
    const formData = new FormData();

    if (!files[0]) return;

    formData.append("file", files[0], files[0].name);

    if (
      files[0].type !== "application/vnd.ms-excel" &&
      files[0].type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      //not sure if that's the right type, you'll have to log it first
      this.setState({ status: "Type de fichier incorrect, .xlsx requis" });
      setTimeout(() => {
        this.setState({ status: "En attente d'un fichier..." });
      }, 3000);
      return;
    }

    const token = localStorage.getItem("fleetToken");
    let myHeaders = new Headers();
    myHeaders.append("content-type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", token);

    const options = {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: token,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        if (percent < 100) {
          this.setState({
            uploadPercentage: percent,
            status: "Téléchargement du fichier en cours...",
          });
        }
      },
    };

    // Details of the uploaded file
    console.log(files[0]);

    axios
      .post(this.props.BASE_URL + "/upload/xlsx", formData, options)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.setState({
            uploadPercentage: 100,
            status: "Fichier téléchargé avec succès",
          });
          toast.info("Fichier téléchargé avec succès.");
          setTimeout(() => {
            this.setState({
              uploadPercentage: 0,
              status: "En attente d'un fichier...",
            });
          }, 3000);
        } else {
          this.setState({
            uploadPercentage: 0,
            status:
              "Une erreur est survenue, veuillez prendre contact avec le support.",
          });
          toast.error(
            "Une erreur est survenue, veuillez prendre contact avec le support."
          );
          setTimeout(() => {
            this.setState({
              uploadPercentage: 0,
              status: "En attente d'un fichier...",
            });
          }, 3000);
        }
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          if (err.response?.data?.status === "Failed") {
            toast.error(
              "Une erreur est survenue lors de la lecture du fichier, veuillez vérifier son contenu puis réessayer."
            );
            this.setState({
              uploadPercentage: 0,
              status:
                "Une erreur est survenue lors de la lecture du fichier, veuillez vérifier son contenu puis réessayer.",
            });
          } else {
            toast.error(
              "Une erreur est survenue, veuillez contacter le support."
            );
            this.setState({
              uploadPercentage: 0,
              status: "Une erreur est survenue, veuillez contacter le support.",
            });
          }
        } else {
          toast.error(
            "Une erreur est survenue, veuillez contacter le support."
          );
          this.setState({
            uploadPercentage: 0,
            status: "Une erreur est survenue, veuillez contacter le support.",
          });
        }

        setTimeout(() => {
          this.setState({
            uploadPercentage: 0,
            status: "En attente d'un fichier...",
          });
        }, 5000);
      });
  };

  render() {
    return (
      <div className="generic-page-wrapper">
        <div className="header-fleet">
          <div className="header-fleet-inner">
            <div>
              <div className="header-fleet-title">
                <h3>Import</h3>
              </div>

              <h4>Importez vos fichiers .XLSX depuis cette page.</h4>
            </div>
            <div>
              <div className={"filter-status-wrapper"}>
                <div
                  className={"filter-status-wrapper-child"}
                  id={"filter-status-wrapper"}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <Row>
          <Col>
            <div className={"fleet-item-upload"}>
              <div className="upload-header-inner">
                <h5>Téléchargement du fichier de tournée</h5>
                <p>
                  Importez votre fichier de tournée en Glissez/Déposez ou
                  recherchez sur votre PC
                </p>
              </div>
              <DragAndDrop handleDrop={this.handleDrop}>
                <div className={"upload-inner"}>
                  <div>
                    <img
                      src={notebook}
                      className={"img-illustration-fleet-upload"}
                      alt={"notebook"}
                    />
                  </div>
                  <span className="fleet-upload-text-label">
                    Glissez/Déposez votre fichier ici, ou{" "}
                    <label htmlFor={"file-input"}>
                      <span className={"fleet-click-label-input"}>
                        Rechercher sur votre PC
                      </span>
                    </label>
                  </span>
                  <div>
                    <span className="badge-file-type">.XLSX</span>
                  </div>
                </div>
              </DragAndDrop>

              <div className={"upload-inner-inprogress"}>
                <h5>{this.state.status}</h5>

                <ProgressBar
                  variant="info"
                  now={this.state.uploadPercentage}
                  label={`${this.state.uploadPercentage}%`}
                />
              </div>

              <input
                type={"file"}
                id={"file-input"}
                className={"form-control"}
                style={{ display: "none" }}
                onChange={this.uploadFile}
                accept=".xlsx, .xls, .csv"
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    BASE_URL: state.BASE_URL,
  };
};

export default connect(mapStateToProps)(AddFile);
