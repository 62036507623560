import React, { Component } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import IssueBoardList from "./issueBoardList/IssueBoardList";
import FleetLoader from "../../GenericComponent/FleetLoader/FleetLoader";

let timerFetchIssues = null;
export class IssueBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentSize: props.componentSize,
      openIssues: props.openIssues,
      solvedIssues: props.solvedIssues,
      sumCount: 0,
      isLoading: false,
      error: "",
    };
    this._isMounted = false;
  }

  fetchIssue = () => {
    const url = new URL(this.props.BASE_URL.concat("/issues/withBlsAndTours"));
    const token = localStorage.getItem("fleetToken");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

    if (this.props.debugMode) {
      console.log(
        "GET request @ ",
        this.props.BASE_URL.concat("/issues/withBlsAndTours")
      );
    }

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Success") {
              this._isMounted &&
                this.setState({
                  openIssues: result.openIssues,
                  solvedIssues: result.solvedIssues,
                  isLoading: false,
                });
            } else {
              this._isMounted &&
                this.setState({
                  isLoading: false,
                });
            }
          });
        } else {
          response.json().then((result) => {
            this._isMounted &&
              this.setState({
                isLoading: false,
              });
          });
        }
      })
      .catch((error) => {
        this._isMounted &&
          this.setState({
            isLoading: false,
            error:
              "Il y a eu un problème avec l'opération fetch" + error.message,
          });
      });
  };

  solveIssue = (issueId) => {
    this._isMounted &&
      this.setState({
        isLoading: true,
      });
    const url = new URL(this.props.BASE_URL.concat("/issues/", issueId));
    const token = localStorage.getItem("fleetToken");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = {
      method: "PUT",
      headers: myHeaders,
      cache: "no-cache",
      body: JSON.stringify({
        status: "SOLVED",
      }),
    };

    if (this.props.debugMode) {
      console.log(
        "PUT request @ ",
        this.props.BASE_URL.concat("/issues/", issueId)
      );
    }

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Success") {
              this._isMounted &&
                this.setState({
                  openIssues: result.openIssues,
                  solvedIssues: result.solvedIssues,
                });
              this._isMounted && this.fetchIssue();
            }
          });
        } else {
          response.json().then((result) => {
            console.error("Mauvaise réponse du réseau", result);

            this.setState({
              isLoading: false,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.error(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  };

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.fetchIssue();
    timerFetchIssues = setInterval(() => {
      this._isMounted && this.fetchIssue();
    }, 10000);
  }

  componentWillUnmount() {
    this._isMounted = false;

    // Lors du déchargement de la page, on de-register le setInterval qui va fetch les issues.

    clearInterval(timerFetchIssues);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this?.state?.openIssues !== prevState?.openIssues ||
      this?.state?.solvedIssues !== prevState?.solvedIssues
    ) {
      this.setState({
        sumCount: this?.state?.openIssues?.length,
      });
    }
  }

  render() {
    return (
      <Col xs={this.state.componentSize}>
        <div className="card-fleet">
          <h4>
            Réclamations <Badge bg="secondary">{this.state.sumCount}</Badge>
          </h4>
          <PerfectScrollbar>
            <div>
              <div className={"card-fleet-header-2"}>
                <span className={"card-fleet-header-2-title "}>
                  <span className={"fleet-item-status late"}>
                    {this.state.openIssues?.length > 0 ? "Ouvertes" : "Ouverte"}
                  </span>
                </span>
                <span className={"card-fleet-header-2-button"}> </span>
              </div>
              <div className={"issues-return-container-wrapper"}>
                <IssueBoardList
                  list={this.state.openIssues}
                  status={"open"}
                  isLoading={this.state.isLoading}
                  solveIssueHandler={this.solveIssue}
                />
              </div>
              <div className={"card-fleet-header-2"}>
                <span className={"card-fleet-header-2-title"}>
                  <span className={"fleet-item-status ok"}>
                    {this.state.solvedIssues?.length > 0
                      ? "Traitées"
                      : "Traitée"}
                  </span>
                </span>
                <span className={"card-fleet-header-2-button"}></span>
              </div>
              <div className={"issues-return-container-wrapper mb-3"}>
                <IssueBoardList
                  list={this.state.solvedIssues}
                  status={"solved"}
                />
              </div>
            </div>
          </PerfectScrollbar>
          <FleetLoader isLoading={this.state.isLoading} />
        </div>
      </Col>
    );
  }
}

export default IssueBoard;
