import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faList } from "@fortawesome/free-solid-svg-icons";
import "./dashboard-claim.scss";

const DashboardClaim = (props) => {
  return (
    <div
      key={"index"}
      className={`issue-container ${props.status !== "open" ? "disabled" : ""}`}
    >
      <div className={"decoration"}>
        <span className={"left-ticket"}></span>
        <span className={"right-ticket"}></span>
      </div>
      <div className="issue-container-header">
        <div className="left-part">
          <Link to={{pathname: "/tour/", state: {blId: props.claim?.delivery_detail_key}}}>
            <span
              className={"issue-container-header-bl"}
              style={{ position: "relative", zIndex: 1 }}
            >
              <FontAwesomeIcon
                icon={faList}
                className="issue-container-header-bl-icon"
              />{" "}
              {"BL " + props.claim?.delivery_detail_key || "BL 00000000"}
            </span>
          </Link>
        </div>
        <div className="right-part">
          <span
            className={"issue-container-header-hour"}
            style={{ position: "relative", zIndex: 1 }}
          >
            {new Date(props.claim?.issue_created_at).toLocaleDateString(
              "fr-FR",
              {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }
            ) || "Pas d'information de date"}
          </span>
        </div>
      </div>
      <div className="issue-container-body">
        <p>
          {" "}
          {props.claim?.issue_reason_message ||
            "Pas d'information sur la raison"}
        </p>
        {props.status === "open" ? (
          <button
            className={"btn issue-container-body-button"}
            onClick={() => props.solveIssueHandler(props.claim?.issue_id)}
            disabled={props.isLoading}
          >
            Traiter
          </button>
        ) : (
          <div
            className={"btn issue-container-body-button-placeholder"}
            disabled={props.isLoading}
          ></div>
        )}
      </div>
    </div>
  );
};

export default DashboardClaim;
