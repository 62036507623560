import React, { useRef, useState, useEffect } from "react";

import notebook from "../../../assets/images/isometric/notebook.png";
import bgdeco from "../../../assets/images/background/background_decoration2x.png";
import bgdeco2 from "../../../assets/images/background/background_decoration.png";
import axios from "axios";
import {
  Row,
  Col,
  ProgressBar,
  Button,
  Accordion,
  AccordionContext,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import TourItemOptimization from "../../TourItemOptimization/TourItemOptimization";
import OptimizationDetailModal from "./optimization-detail-modal/optimization-detail-modal";
import iconRoue from "../../../assets/images/optimisation/icon-roue.png";
import DragAndDrop from "../../GenericComponent/DragAndDrop/DragAndDrop";
import { connect, useSelector } from "react-redux";
import MapFleetOptimization from "../../pages/Optimization/map-fleet-optimization/map-fleet-optimization";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faGear } from "@fortawesome/free-solid-svg-icons";
import "../../App/App.scss";
import "./Optimization.scss";
import { toast } from "react-toastify";

function Optimization(props) {
  /**
   ******* States & hooks ********
   **/
  const config = useSelector((state) => state.config);
  const history = useHistory();
  const BASE_URL = useSelector((state) => state.BASE_URL);
  const [uploadPercentage, setPercentage] = useState(0);
  const [status, setStatus] = useState("En attente d'un fichier...");
  const [activeId, setActiveId] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);

  const [selectedTour, setSelectedTour] = useState(null);

  const [openedTourKey, setOpenedTourKey] = useState(null);
  const [tourOpen, setOpenedTour] = useState(null);
  const [tourOpenIndex, setTourOpenIndex] = useState(0);

  const [optimizationData, setOptimizationData] = useState(null);
  const [AllStartPlatform, setAllStartPlatform] = useState(null);

  const UploadSection = useRef();
  const OptimizationSection = useRef();
  const ConfirmationSection = useRef();

  const [step, setStep] = useState(1);
  const colorArray = [
    ["#17bebb", "#17bebb"],
    ["#bee9e8", "#62b6cb"],
    ["#9f86c0", "#be95c4"],
    ["#f79d65", "#f4845f"],
    ["#fdc43f", "#fdbe39"],
    ["#7209b7", "#3a0ca3"],
    ["#ef233c", "#d80032"],
    ["#e05780", "#b9375e"],
    ["#0e9594", "#127475"],
    ["#147df5", "#580aff"],
    ["#3c096c", "#240046"],
    ["#98f5e1", "#b9fbc0"],
    ["#f72585", "#b5179e"],
    ["#52b69a", "#34a0a4"],
    ["#b5c99a", "#97a97c"],
    ["#adb5bd", "#6c757d"],
    ["#dfe7fd", "#cddafd"],
    ["#ffb4a2", "#e5989b"],
    ["#c1121f", "#780000"],
    ["#ddbea9", "#b98b73"],
    ["#003566", "#001d3d"],
    ["#a4133c", "#800f2f"],
    ["#ffc300", "#ffd60a"],
    ["#455e89", "#5c4d7d"],
    ["#70e000", "#38b000"],
    ["#00a8e8", "#007ea7"],
    ["#55828b", "#3b6064"],
    ["#1e88e5", "#1976d2"],
    ["#f79d65", "#f4845f"],
    ["#3c096c", "#240046"],
    ["#4f772d", "#31572c"],
    ["#805b10", "#76520e"],
    ["#283845", "#202c39"],
    ["#d3bccc", "#e8d7f1"],
    ["#49dcb1", "#49beaa"],
    ["#ad2e24", "#81171b"],
    ["#dec0f1", "#9fa0ff"],
  ];

  function pickColorAtIndex(index) {
    return colorArray[index % colorArray.length];
  }

  /**
   ******* Effects ********
   **/

  // Get the opened tour & set coordinates
  useEffect(() => {
    if (optimizationData) {
      if (openedTourKey) {
        let found = false;
        for (let i = 0; i < optimizationData.length; i++) {
          for (let j = 0; j < optimizationData[i].vehicles.length; j++) {
            if (
              optimizationData?.[i]?.vehicles?.[j]?.vehicule_key ===
              openedTourKey
            ) {
              let _openedTour = optimizationData[i].vehicles[j];
              _openedTour.platformCoods = [
                optimizationData[i]?.latitude,
                optimizationData[i]?.longitude,
              ];
              setOpenedTour(optimizationData[i].vehicles[j]);
              setTourOpenIndex(j);
              break;
            }
          }
          if (found) break;
        }
      } else {
        setOpenedTour(null);
      }
    }
  }, [openedTourKey, optimizationData]);

  // Get all start platform & set it in state
  useEffect(() => {
    if (optimizationData) {
      let _platform = [];
      for (let i = 0; i < optimizationData.length; i++) {
        _platform.push([
          optimizationData[i].longitude,
          optimizationData[i].latitude,
        ]);
      }
      setAllStartPlatform(_platform);
    }
  }, [optimizationData]);

  // Scroll into DOM element according to step
  useEffect(() => {
    switch (step) {
      case 2:
        OptimizationSection.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
        break;
      case 3:
        ConfirmationSection.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
        break;
      default:
        console.warn("unknown step", step);
    }
  }, [step]);

  /**
   ******* Functions ********
   **/

  const uploadFile = (e) => {
    if (e.target.files) {
      handleDrop(e.target.files);
      e.target.value = null;
    }
  };

  const handleDrop = (files) => {
    for (var i = 0; i < files.length; i++) {
      if (!files[i].name) return;
      console.log("depot du fichier", files[i].name, files);
    }

    // Create an object of formData
    const formData = new FormData();

    if (!files[0]) return;

    formData.append("file", files[0], files[0].name);

    if (
      files[0].type !== "application/vnd.ms-excel" &&
      files[0].type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      //not sure if that's the right type, you'll have to log it first
      setStatus("Type de fichier incorrect, .xlsx requis");
      setTimeout(() => {
        setStatus("En attente d'un fichier...");
      }, 5000);
      return;
    }

    const token = localStorage.getItem("fleetToken");
    let myHeaders = new Headers();
    myHeaders.append("content-type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", token);

    const options = {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: token,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        if (percent <= 100) {
          setPercentage(percent);
          setStatus("Téléchargement du fichier en cours...");
        }
      },
    };

    // Details of the uploaded file
    console.log(files[0]);

    axios
      .post(BASE_URL + "/optimization/xlsx", formData, options)
      .then((res) => {
        if (res.status === 200 && res?.data?.status === "Success") {
          setPercentage(100);
          setStatus("Fichier téléchargé avec succès");

          console.log(
            "resultat de l'optimisation",
            res?.data?.distanceOptimizedStartingPlatform
          );
          setOptimizationData(res?.data?.distanceOptimizedStartingPlatform);
          setStep(2);

          setTimeout(() => {
            setPercentage(0);
            setStatus("En attente d'un fichier...");
          }, 3000);
        }
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          setPercentage(0);
          setStatus(
            err.response?.data?.error ??
            "Une erreur est survenue lors de la lecture du fichier, veuillez vérifier son contenu puis réessayer."
          );
        } else {
          setPercentage(0);
          setStatus("Une erreur est survenue, veuillez contacter le support.");
        }
      });
  };

  function toTime(seconds) {
    var date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 5).replace(':', "h") + "m";
  }

  /**
   ******* Render ********
   **/

  return (
    <>
      <div className="generic-page-wrapper">
        <div className={"header-fleet"}>
          {/* <img
            src={bgdeco2}
            alt="background_decoration_fleet"
            className="bg-deco-fleet"
          /> */}
          <div className="header-fleet-inner">
            <div>
              <h3>Optimisez vos tournées</h3>
              <h4>
                Importez, optimisez et déployez vos tournées directement depuis
                cette page.
              </h4>
            </div>
            <div>
              {config.fleet_optimization ? (
                <Button
                  variant=""
                  onClick={() => history.push("/fleet-management")}
                  className="btn-opti"
                  style={{ backgroundColor: "#079992", borderRadius: 12 }}
                >
                  <FontAwesomeIcon icon={faGear} height="10" color="white" />
                </Button>
              ) : null}
            </div>
          </div>
        </div>
        <Row>
          <Col>
            <div
              className={"fleet-item-upload"}
              ref={UploadSection}
              style={
                step !== 1 ? { opacity: 0.3, pointerEvents: "none" } : null
              }
            >
              <div className="upload-header-inner">
                <h5>Téléchargement du fichier de tournée</h5>
                <p>
                  Importez votre fichier de tournée en Glissez/Déposez ou
                  recherchez sur votre PC
                </p>
              </div>

              <DragAndDrop handleDrop={handleDrop}>
                <div className={"upload-inner"}>
                  <div>
                    <img
                      src={notebook}
                      className={"img-illustration-fleet-upload"}
                      alt={"notebook"}
                    />
                  </div>
                  <span className="fleet-upload-text-label">
                    Glissez/Déposez votre fichier ici, ou{" "}
                    <label htmlFor={"file-input"}>
                      <span className={"fleet-click-label-input"}>
                        Rechercher sur votre PC
                      </span>
                    </label>
                  </span>
                  <div>
                    <span className="badge-file-type">.XLSX</span>
                  </div>
                </div>
              </DragAndDrop>

              <div className={"upload-inner-inprogress"}>
                <h5>{status}</h5>

                <ProgressBar animated variant="" now={uploadPercentage} />
              </div>

              <input
                type={"file"}
                id={"file-input"}
                className={"form-control"}
                style={{ display: "none" }}
                onChange={uploadFile}
                accept=".xlsx, .xls, .csv"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className={"fleet-item-upload"}
              ref={OptimizationSection}
              style={
                step !== 2 ? { opacity: 0.3, pointerEvents: "none" } : null
              }
            >
              <div className="upload-main-wrapper">
                <div className="left-part">
                  <div className="upload-header-inner">
                    <h5>Optimisation automatisée de tournée</h5>
                    <p>
                      Cliquez sur chacune des tournées pour les voir apparaitre
                      sur la carte ou le bouton "plus" pour visualiser le
                      chargement du camion
                    </p>
                  </div>
                  <div className="upload-select-simulation-type-wrapper">
                    <div className="upload-select-simulation-type">
                      <Button variant="" className="btn-simulation">
                        <span className="btn-simulation-title">
                          Simulation N°1
                        </span>
                        <span className="btn-simulation-desc">
                          Le plus rapide
                        </span>
                      </Button>
                    </div>
                  </div>
                  <div className="tour-upload-item-wrapper">
                    {optimizationData?.length > 0 ? (
                      <Accordion>
                        {optimizationData?.map?.((platform, index) => {
                          return (
                            <React.Fragment
                              key={`platform_${platform?.city}_${platform?.address}_${platform?.zipcode}`}
                            >
                              {platform?.vehicles?.length > 0 ? (
                                <>
                                  {platform?.vehicles.map?.((value, index) => {
                                    return (
                                      <TourItemOptimization
                                        base_url={props.base_url}
                                        key={`TourItemWrapper_${value?.vehicule_key}`}
                                        tour={value}
                                        index={index}
                                        accordionContext={AccordionContext}
                                        activeId={activeId}
                                        setActiveId={setActiveId}
                                        setModalShow={setModalShow}
                                        setOpenedTour={setOpenedTourKey}
                                        openedTour={openedTourKey}
                                        setSelectedTour={setSelectedTour}
                                        color={pickColorAtIndex(index)[1]}
                                      />
                                    );
                                  })}
                                </>
                              ) : !props.isLoading ? (
                                <div>y a R</div>
                              ) : (
                                <div>
                                  <div className={"loader-section"}>
                                    loading
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </Accordion>
                    ) : !props.isLoading ? (
                      optimizationData ? (
                        // No platform
                        <div></div>
                      ) : (
                        // Not imported yet
                        <div></div>
                      )
                    ) : (
                      <div>
                        <div className={"loader-section"}></div>
                      </div>
                    )}
                  </div>
                  <div className="tour-upload-button">
                    <Button
                      variant=""
                      className="button-confirm"
                      onClick={() => setStep(3)}
                    >
                      Je confirme l'ordre de mes tournées
                    </Button>
                  </div>
                  <img
                    src={bgdeco}
                    alt="background_decoration_fleet"
                    className="bg-deco-fleet"
                  />
                </div>
                <div className="right-part">
                  <div className="map-wrapper">
                    <div className="map">
                      <MapFleetOptimization
                        geometry={tourOpen?.routes?.[0]?.geometry?.coordinates}
                        geometry_index={tourOpenIndex}
                        waypoints={tourOpen?.steps}
                        platformStart={tourOpen?.platformCoods}
                        allPlatformStart={AllStartPlatform}
                        optimizationData={optimizationData}
                        pickColorAtIndex={pickColorAtIndex}
                      />
                      {tourOpen ? (
                        <div className="map-overlay">
                          <div className="map-overlay-inner">
                            <div className="overlay-kpi">
                              <div>{tourOpen?.steps?.length ?? "00"}</div>
                              <div>étapes</div>
                            </div>
                            <div className="ant-divider-vertical" />
                            <div className="overlay-kpi">
                              <div>
                                {parseFloat(
                                  tourOpen?.estimated_travel_distance_in_km
                                ).toFixed(2) ?? "00"}
                              </div>
                              <div>Kilométres</div>
                            </div>
                            <div className="ant-divider-vertical" />
                            <div className="overlay-kpi">
                              <div>
                                {tourOpen?.estimated_number_of_seconds
                                  ? toTime(
                                    tourOpen?.estimated_number_of_seconds
                                  )
                                  : null}
                              </div>
                              <div>Estimation</div>
                            </div>
                            <OverlayTrigger
                              trigger="hover"
                              placement="top"
                              overlay={
                                <Tooltip>
                                  Inclu <strong>{tourOpen?.estimated_number_of_seconds_break
                                    ? toTime(
                                      tourOpen?.estimated_number_of_seconds_break
                                    )
                                    : "aucun"}</strong> temps de <br /> chargement / déchargement
                                </Tooltip>
                              }
                            >
                              <span className="fleet-tooltip-opti">
                                <FontAwesomeIcon
                                  icon={faCircleInfo}
                                  style={{ fontSize: 16 }}
                                  color="#757476"
                                />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={"fleet-item-upload-container"}>
              <img
                src={bgdeco2}
                alt="background_decoration_fleet"
                className="bg-deco-fleet"
              />
              <div
                className={"fleet-item-upload"}
                ref={ConfirmationSection}
                style={
                  step !== 3 ? { opacity: 0.3, pointerEvents: "none" } : null
                }
              >
                <div className="upload-header-inner">
                  <h5>Confirmation</h5>
                  <p>
                    En validant la publication des tournées de mon annonce,
                    j’accepte les conditions générales d’utilisation, les règles
                    de publication fleet et j’autorise fleet à publier/modifier
                    les tournées.
                  </p>
                </div>
                <div className="upload-button-confirm-container">
                  <div className="button-wrapper">
                    <Button
                      variant=""
                      className="button-back"
                      onClick={() => setStep(2)}
                    >
                      Retour à la derniere étape
                    </Button>
                    <Button
                      variant=""
                      className="button-confirm"
                      onClick={() =>
                        toast.info("Publication pas encore mis en œuvre")
                      }
                    >
                      Publier les tournées
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <OptimizationDetailModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedtour={selectedTour}
      />
    </>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    BASE_URL: state.BASE_URL,
  };
};

export default connect(mapStateToProps)(Optimization);
