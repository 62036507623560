import React, { Component } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import lottie from "lottie-web";
import animationData from "../../Home/3075-delivery-van";
import { Link } from "react-router-dom";

import mapboxgl from "mapbox-gl";

import in_out_disable from "./1.0.png";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

let animObj = null;
let mapboxInterval = null;
export class Delivery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentSize: props.componentSize,
      tours: props.tours,
      firstCardCount: 0,
      secondCardCount: 0,
      thirdCardCount: 0,
      sumCount: 0,
      lng: 4.0333,
      lat: 49.25,
      zoom: 7,
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/bazam/ck9bl6ycn050g1ip509540isz",
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });

    map.on("load", () => {
      map.loadImage(in_out_disable, function (error, image) {
        if (error) throw error;

        map.addImage("mapPin", image, { sdf: true });

        map.on("click", "points", function (e) {
          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = e.features[0].properties.description;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(map);
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on("mouseenter", "points", function () {
          map.getCanvas().style.cursor = "pointer";
        });

        // Change it back to a pointer when it leaves.
        map.on("mouseleave", "points", function () {
          map.getCanvas().style.cursor = "";
        });
      });
      this.drawmarker(map);
    });

    this.fetchKPI();
    mapboxInterval = setInterval(async () => {
      this.fetchKPI();
      this.drawmarker(map);
    }, 5000);
  }

  componentWillUnmount() {
    // Lors du déchargement de la page, on de-register le setInterval qui va fetch les étapes pour mapbox.
    this._isMounted = false;

    clearInterval(mapboxInterval);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.firstCardCount !== prevState.firstCardCount ||
      this.state.secondCardCount !== prevState.secondCardCount ||
      this.state.thirdCardCount !== prevState.thirdCardCount
    ) {
      this.setState({
        sumCount:
          this.state.firstCardCount +
          this.state.secondCardCount +
          this.state.thirdCardCount,
      });
    }
  }

  drawmarker = (map) => {
    this.fetchAllSteps().then(async (data) => {
      let steps = data || [];

      let features = [];
      let new_markers = [];
      let index = 0;

      for (let el2 of steps) {
        if (index < 50) {
          index++;
          await fetch(
            "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
              el2.end_address?.street +
              " " +
              el2.end_address?.city +
              " " +
              el2.end_address?.zipcode +
              " France.json?access_token=pk.eyJ1IjoiYmF6YW0iLCJhIjoiY2tjdnN6dXQ0MDdnaTJ2cHF1cG12Zm15NSJ9.Z1Sb2Umu0sMaw4o77CK8Sw"
          )
            .then((response) => {
              return response.json();
            })
            .then((json) => {
              let center = "";

              center = json.features[0].center;

              if (json && json.features && json.features[0]) {
                let feature = {
                  type: "Feature",
                  properties: {
                    step_status: el2.status,
                    description:
                      '<div class="desc-card">' +
                      '<strong class="title">' +
                      (el2.end_address?.name ? el2.end_address.name : "n.c") +
                      "</strong><br>" +
                      '<strong class="desc">' +
                      ((el2.end_address?.street
                        ? el2.end_address?.street
                        : "") +
                        " " +
                        (el2.end_address?.city ? el2.end_address?.city : "") +
                        " " +
                        (el2.end_address?.zipcode
                          ? el2.end_address?.zipcode
                          : "")) +
                      "</strong><br>" +
                      '<span class="status">' +
                      this.translateStatus(el2.status) +
                      "</span>" +
                      "</div>",
                  },
                  geometry: {
                    type: "Point",
                    coordinates: [
                      json.features[0].center[0],
                      json.features[0].center[1],
                    ],
                  },
                };

                new_markers.push(new mapboxgl.Marker().setLngLat(center));

                return feature;
              }

              return null;
            })
            .then((feature) => {
              if (feature) {
                features.push(feature);
              }
            });
        }
      }

      if (map.getLayer("points")) {
        map.removeLayer("points");
      }
      if (map.getSource("steps")) {
        map.getSource("steps").setData({
          type: "FeatureCollection",
          features: features,
        });
      } else {
        map.addSource("steps", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: features,
          },
          cluster: false, // Enable clustering
          clusterRadius: 50, // Radius of each cluster when clustering points
          clusterMaxZoom: 12, // Max zoom to cluster points on
        });
      }

      map.addLayer({
        id: "points",
        type: "symbol",
        source: "steps",
        layout: {
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-image": "mapPin",
          "icon-size": 0.6,
        },
        paint: {
          // make circles larger as the user zooms from z12 to z22
          "icon-color": [
            "match",
            ["get", "step_status"],
            "CREATED",
            "#079992",
            "STARTED",
            "#079992",
            "DELIVERED",
            "#78e08f",
            "PARTIALLY_DELIVERED",
            "#e55039",
            "CANCELED",
            "#e55039",
            "LATE",
            "#fa983a",
            /* other */ "#0e44cc",
          ],
          // color circles by ethnicity, using a match expression
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
        },
      });
    });
  };

  translateStatus = (word) => {
    switch (word) {
      case "CREATED":
        return "Planifiée";
      case "STARTED":
        return "Livraison en cours";
      case "DELIVERED":
        return "Livrée";
      case "PARTIALLY_DELIVERED":
        return "Partiellement délivrée";
      case "CANCELED":
        return "Non livrée";
      case "LATE":
        return "En retard";
    }
  };

  fetchKPI = () => {
    const url = new URL(this.props.BASE_URL.concat("/steps/KPI"));
    const token = localStorage.getItem("fleetToken");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

    // console.log("GET request @ ",  this.props.BASE_URL.concat('/steps/KPI'));

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Success") {
              this._isMounted &&
                this.setState({
                  firstCardCount: result.KPI.delivered,
                  secondCardCount: result.KPI.in_progress,
                  thirdCardCount: result.KPI.canceled,
                });
            }
          });
        } else {
          console.log("Mauvaise réponse du réseau");
          response.json().then(function (result) {
            // console.log("result", result);
          });
        }
      })
      .catch(function (error) {
        // console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
      });
  };

  fetchAllSteps = () => {
    const url = new URL(this.props.BASE_URL + "/steps");
    const token = localStorage.getItem("fleetToken");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

    // console.log("GET request @ ",  this.props.BASE_URL.concat('/steps'));

    return fetch(url, myInit)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === "Success") {
          // console.log(result.steps)
          return result.steps || [];
        }
      })
      .then((data) => {
        return data;
      })
      .catch(function (error) {
        // console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
      });
  };

  render() {
    return (
      <Col xs={this.state.componentSize}>
        <div className="card-fleet xl">
          <div className="tootip-card-fleet">
            {/*<OverlayTrigger*/}
            {/*key={"right"}*/}
            {/*placement={"right"}*/}
            {/*overlay={*/}
            {/*<Tooltip id={`tooltip-${"right"}`}>*/}
            {/*Tooltip on <strong>{"right"}</strong>.*/}
            {/*</Tooltip>*/}
            {/*}*/}
            {/*>*/}
            {/*<FontAwesomeIcon icon={faInfoCircle}/>*/}
            {/*</OverlayTrigger>*/}
          </div>

          <Container fluid>
            <Row>
              <Col>
                <h4>
                  Étapes <Badge bg="secondary">{this.state.sumCount}</Badge>
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={"card-fleet-number-wrapper"}>
                  <div
                    className={"card-fleet-number-container-full-width"}
                    style={{ backgroundColor: "#78e08f" }}
                  >
                    <span className={"card-fleet-number-container-number"}>
                      {this.state.firstCardCount}
                    </span>
                    <span className={"card-fleet-number-container-label"}>
                      Livrée(s)
                    </span>
                  </div>
                  <div
                    className={"card-fleet-number-container-full-width"}
                    style={{ backgroundColor: "#079992" }}
                  >
                    <span className={"card-fleet-number-container-number"}>
                      {this.state.secondCardCount}
                    </span>
                    <span className={"card-fleet-number-container-label"}>
                      En cours
                    </span>
                  </div>
                  <div
                    className={"card-fleet-number-container-full-width"}
                    style={{ backgroundColor: "#e55039" }}
                  >
                    <Link to={"/tour?status=issue"}>
                      <span className={"card-fleet-number-container-number"}>
                        {this.state.thirdCardCount}
                      </span>
                      <span className={"card-fleet-number-container-label"}>
                        Non livrée(s)
                      </span>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="card-fleet-map-view-container">
            <div
              ref={(el) => (this.mapContainer = el)}
              className="mapContainer"
            />
          </div>
        </div>
      </Col>
    );
  }
}

export default Delivery;
