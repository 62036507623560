import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  Suspense,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullhorn,
  faChevronRight,
  faClipboardList,
  faFileDownload,
  faGlobeEurope,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import bgdeco2 from "../../../assets/images/background/background_decoration.png";
import noDataIllu from "../../../assets/images/illustration/undraw_map_re_60yf@2x.png";
import { useHistory, useLocation } from "react-router";

import TourItemDashboard from "../../pages/Dashboard/TourItemDashboard/TourItemDashboard";
import TourItemDashboardLoader from "../../pages/Dashboard/tour-item-dashboard-loader/tour-item-dashboard-loader";
import DashboardClaimModal from "../../pages/Dashboard/dashboard-claim-modal/dashboard-claim-modal";
import { Button, Spinner } from "react-bootstrap";

import SimpleBarReact from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { toast } from "react-toastify";

import { tour_status } from "../../../constants/tour_status";

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import "../../App/App.scss";
import "./Home.scss";
import { io } from "socket.io-client";
import SkeletonLoader from "tiny-skeleton-loader-react";

import Kpi from "../../pages/Dashboard/kpi/kpi";
import CustomDatePickerDashboard from "../../pages/Dashboard/custom-date-picker-dashboard/custom-date-picker-dashboard";

import { useDispatch, useSelector } from "react-redux";
import { setNavWidth } from "../../../reducer/Action";

registerLocale("fr", fr);

const MapFleetDashboard = React.lazy(() =>
  import("../../pages/Dashboard/map-fleet-dashboard/map-fleet-dashboard")
);

const FluxFleetDashboard = React.lazy(() =>
  import("../../pages/Dashboard/flux-fleet-dashboard/flux-fleet-dashboard")
);

const HomeV2 = () => {
  /******
   *
   * States
   *
   ******/

  const [tours, setTours] = useState([]);
  const [kpi, setKpi] = useState([]);
  const [flux, setFlux] = useState([]);

  const [showClaiming, setShowClaiming] = useState(false);
  const [showReserve, setShowReserve] = useState(false);
  const [date, setDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadingResume, setIsDownloadingResume] = useState(false);

  const [selectedTourID, setSelectedTourID] = useState(null);
  const [selectedTour, setSelectedTour] = useState(null);
  const [tourStatus, setTourStatus] = useState("all");
  const [fluxStatus, setFluxStatus] = useState("all");
  const [reservesNumber, setReservesNumber] = useState(0);

  const [isMapReady, setIsMapReady] = useState(false);
  const [isMapUpdating, setIsMapUpdating] = useState(false);

  const [issues, setIssues] = useState({
    openIssues: [],
    solvedIssues: [],
  });

  const BASE_URL = useSelector((state) => state.BASE_URL);
  const ref = useRef();
  const history = useHistory();

  /******
   *
   * functions
   *
   ******/

  // Get the resume for a given date
  function FetchDataServerSidedResume() {
    let url = new URL(BASE_URL + "/document/resumeForDate");
    const token = localStorage.getItem("fleetToken");

    if (date) {
      const new_date = date?.toISOString?.().substring(0, 10);
      url = new URL(
        BASE_URL +
          "/document/resumeForDate?start_date=" +
          new_date +
          "&end_date=" +
          new_date
      );
    } else {
      return false;
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = {
      method: "GET",
      headers: myHeaders,
      cache: "no-cache",
    };

    return fetch(url, myInit)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === "Success" && result.url) {
          return result.url;
        } else {
          toast.info("Pas de tournée pour ce jour.");
        }
      })
      .catch((error) => {
        toast.error("Problème lors de la récupération des tournées.");
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  }

  async function FetchClaimData() {
    const url = new URL(
      BASE_URL.concat(
        "/issues/withBlsAndTours?date=" + date?.toISOString?.().substring(0, 10)
      )
    );

    const token = localStorage.getItem("fleetToken");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

    await fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Success") {
              setIssues({
                openIssues: [...result.openIssues],
                solvedIssues: [...result.solvedIssues],
              });
            }
          });
        } else {
          toast.error("Problème lors de la récupération des données.");
        }
      })
      .catch((error) => {
        toast.error("Problème réseau lors de la récupération des données.");
      });
  }

  // Handle the download resume button in order to Download the resume
  async function DownloadResume() {
    setIsLoading(true);
    setIsDownloadingResume(true);

    let data_resume = await FetchDataServerSidedResume();

    if (data_resume) {
      window.open(data_resume, "_blank");
    }

    setTimeout(() => {
      setIsLoading(false);
      setIsDownloadingResume(false);
    }, 1000);
  }

  const isFiltering = useCallback(() => {
    return tourStatus !== "all";
  }, [tourStatus]);

  // filter all the tours by the status
  const filteringFunction = useCallback(() => {
    if (!tours) {
      return [];
    }

    if (!isFiltering()) {
      return tours;
    }

    return tours.filter((x) => {
      return x.status?.toUpperCase() === tourStatus?.toUpperCase();
    });
  }, [tours, tourStatus, isFiltering]);

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  /******
   *
   * Effects
   *
   ******/

  /*****
   * will set the selected tour in order to use in the map detail when the user click on a tour
   */

  // socket IO initialization and event listeners declaration
  useEffect(() => {
    // Initialize the socket to the API server
    const socket = io(process.env.REACT_APP_SOCKET_API_URL, {
      query: {
        Authorization: localStorage.getItem("fleetToken"),
      },
    });

    // event received every time a tour is updated
    socket.on("tour-updated", (data) => {
      console.log("tour-updated()");

      setTours((_tours) => {
        const new_tours = [..._tours];

        const indexToReplace = new_tours.findIndex(
          (tour) => tour.tour_id === data.tour_id
        );
        if (indexToReplace !== -1) new_tours[indexToReplace] = data;

        return new_tours;
      });
    });

    // event received every time the KPIs are updated
    socket.on("kpi-updated", (data) => {
      console.log("kpi-updated()");
      setKpi(data);
    });

    // event received every time the flux is updated
    socket.on("flux-updated", (data) => {
      console.log("flux-updated()");
      setFlux((_flux) => {
        return [data, ..._flux];
      });
    });
  }, []);

  // Handle the tour-item click
  useEffect(() => {
    // if a tour is selected, set it to the selected tour state
    if (selectedTourID) {
      const tour = tours?.find((t) => t.tour_id === selectedTourID);
      if (tour) setSelectedTour(tour);
    } else {
      // else, set it to null
      setIsMapUpdating(true);
      setTimeout(() => {
        setSelectedTour(null);
        setIsMapUpdating(false);
      }, 100);
    }
  }, [selectedTourID, tours]);

  // simple-bar recalculate width and height
  useEffect(() => {
    ref.current.recalculate();
  });

  // Did mount effect
  useEffect(() => {
    async function FetchDashboardData() {
      setIsLoading(true);
      const url = new URL(
        BASE_URL.concat(
          "/dashboard/?date=" + date?.toISOString?.().substring(0, 10)
        )
      );

      const token = localStorage.getItem("fleetToken");

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", token);
      let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

      await fetch(url, myInit)
        .then((response) => {
          if (response.ok) {
            response.json().then((result) => {
              if (result.status === "Success") {
                if (result?.dashboardDatas) {
                  setTours(
                    result?.dashboardDatas?.tours_vehicles_and_steps_datas
                  );
                  setFlux(result?.dashboardDatas?.flux);
                  setKpi(result?.dashboardDatas?.kpi);
                } else {
                  setFlux([]);
                  setTours([]);
                  setKpi([]);
                }
                setIsLoading(false);
              }
            });
          } else {
            setIsLoading(false);
            toast.error("Problème lors de la récupération des données.");
          }

          return;
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error("Problème réseau lors de la récupération des données.");
        });
    }

    FetchDashboardData();
    FetchClaimData();
    setSelectedTourID(null);
    setTourStatus("all");
  }, [date, BASE_URL]);
  /******
   *
   * Return
   *
   ******/
  return (
    <>
      <div className={"page-wrapper"}>
        <div className={"header-fleet"}>
          <div className="header-fleet-inner">
            <div className="pe-2">
              <h3>Tour de contrôle</h3>
              <h4>
                Pilotez votre flotte de véhicules depuis cette page pour la date
                du{" "}
                {date.toLocaleDateString("fr-FR", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
                .
              </h4>
            </div>
            <div className="header-fleet-inner-wrapper">
              <DatePicker
                selected={date}
                onChange={(date) => setDate(date)}
                customInput={<CustomDatePickerDashboard />}
                locale="fr"
                dateFormat="dd MMM yyyy"
              />
              <Button
                variant=""
                className="btn-fleet-header"
                style={{ marginRight: "15px" }}
                onClick={(e) => DownloadResume(e)}
                disabled={
                  isLoading || isDownloadingResume || tours?.length === 0
                }
              >
                {isDownloadingResume ? (
                  <div>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <FontAwesomeIcon
                    icon={faFileDownload}
                    className="btn-icon-solo"
                  />
                )}
              </Button>
              <span className={"btn-fleet-wrapper d-flex"}>
                <div style={{ position: "relative" }}>
                  <Button
                    variant=""
                    className="btn-fleet-header"
                    onClick={() => {
                      history.replace({
                        pathname: "/restriction",
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faClipboardList}
                      className="btn-icon"
                    />
                    Réserves
                  </Button>
                  <span
                    className="notification-wrapper"
                    style={
                      kpi?.reserves_kpi?.open_reserves &&
                      kpi?.reserves_kpi?.open_reserves != 0
                        ? { transform: "scale(1.2)" }
                        : null
                    }
                  >
                    {kpi?.reserves_kpi?.open_reserves ?? 0}
                  </span>
                </div>

                <div style={{ position: "relative" }}>
                  <Button
                    variant=""
                    className="btn-fleet-header"
                    onClick={() => setShowClaiming(true)}
                    style={{ marginLeft: "15px" }}
                  >
                    <FontAwesomeIcon icon={faBullhorn} className="btn-icon" />
                    Réclamations
                  </Button>
                  <span
                    className="notification-wrapper"
                    style={
                      kpi?.issues_kpi?.open_issues &&
                      kpi?.issues_kpi?.open_issues != 0
                        ? { transform: "scale(1.2)" }
                        : null
                    }
                  >
                    {kpi?.issues_kpi?.open_issues ?? 0}
                  </span>
                </div>
              </span>
            </div>
          </div>
          {/* <img
            src={bgdeco2}
            alt="background_decoration_fleet"
            className="bg-deco-fleet"
          /> */}
        </div>
        <div className="content-container">
          <div className="content-left-part">
            <div className="kpis-container">
              <Kpi
                title={"Tournées"}
                value={kpi?.tours_kpi?.tours_total}
                icon={faGlobeEurope}
                color={"#079992"}
              />
              <Kpi
                title={"Nombre de réserves"}
                value={kpi?.reserves_kpi?.total_reserves}
                icon={faClipboardList}
                color={"#8b8b8b"}
              />
              <Kpi
                title={"Réclamations totales"}
                value={kpi?.issues_kpi?.total_issues}
                icon={faBullhorn}
                color={"#e55039"}
              />
            </div>
            <div className="tour-header">
              <div className="tour-header-left">
                <span className="tour-header-title">Véhicules en tournée</span>
                <span className="tour-header-number badge">
                  {tours?.length ?? 0}
                </span>
              </div>
              <div className="tour-header-right">
                <select
                  value={tourStatus}
                  onChange={(e) => setTourStatus(e.target.value)}
                  className="tour-select"
                  disabled={tours?.length === 0 || selectedTourID}
                >
                  <option value={tour_status.ALL}>
                    {tour_status?.properties[tour_status.ALL]?.display_name}
                  </option>
                  <option value={tour_status.CREATED}>
                    {tour_status?.properties[tour_status.CREATED]?.display_name}
                  </option>
                  <option value={tour_status.STARTED}>
                    {tour_status?.properties[tour_status.STARTED]?.display_name}
                  </option>
                  <option value={tour_status.ISSUE}>
                    {tour_status?.properties[tour_status.ISSUE]?.display_name}
                  </option>
                  <option value={tour_status.DONE}>
                    {tour_status?.properties[tour_status.DONE]?.display_name}
                  </option>
                </select>
              </div>
            </div>
            <div className="tours-container">
              <div className="wrapper">
                <SimpleBarReact
                  forceVisible="y"
                  ref={ref}
                  style={{
                    maxHeight: "100%",
                    width: "100%",
                    padding: "10px 20px 10px 0px",
                  }}
                >
                  {tours?.length > 0 ? (
                    <>
                      {filteringFunction()?.length > 0 ? (
                        filteringFunction()?.map((tour, tour_index) => {
                          return (
                            <TourItemDashboard
                              key={`tour_${tour?.tour_id}`}
                              selectedTourID={selectedTourID}
                              setselectedTourID={setSelectedTourID}
                              tour={tour}
                              isMapUpdating={isMapUpdating}
                              isMapReady={isMapReady}
                            />
                          );
                        })
                      ) : (
                        <div className="no-tour-container">
                          <img
                            src={noDataIllu}
                            alt="background_decoration_fleet"
                            className="no-tour-img"
                          />
                          <span className="no-tour-title">
                            Aucun véhicule en tournée avec
                            <br /> le filtre sélectionné
                          </span>
                          <span className="no-tour-text">
                            Aucun véhicule n'est en tournée pour la date du{" "}
                            {date.toLocaleDateString("fr-FR", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}{" "}
                            avec le statut{" "}
                            <strong>
                              {
                                tour_status?.properties[tourStatus]
                                  ?.display_name
                              }
                            </strong>
                            .
                          </span>
                        </div>
                      )}
                    </>
                  ) : isLoading ? (
                    <>
                      <TourItemDashboardLoader />
                      <TourItemDashboardLoader />
                    </>
                  ) : (
                    <div className="no-tour-container">
                      <img
                        src={noDataIllu}
                        alt="background_decoration_fleet"
                        className="no-tour-img"
                      />
                      {/* <div
                        style={{ margin: "0 auto" }}
                        className="no-tour-img"
                        ref={animBox}
                      ></div> */}
                      <span className="no-tour-title">
                        Aucun véhicule en tournée
                      </span>
                      <span className="no-tour-text">
                        Aucun véhicule n'est en tournée pour la date du{" "}
                        {date.toLocaleDateString("fr-FR", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                        .
                      </span>
                    </div>
                  )}
                </SimpleBarReact>
              </div>
            </div>
          </div>
          <div className="content-right-part">
            <div className="map-part">
              <div className="tour-header">
                <div className="tour-header-left">
                  <span className="tour-header-title">Carte des étapes</span>
                  <span className="tour-header-number badge">
                    {tours?.length ?? 0}
                  </span>
                </div>
              </div>
              <div className="map-div-container">
                <div className="map-dashboard-container">
                  <Suspense
                    fallback={
                      <div className="map-wrapper">
                        <SkeletonLoader height={"100%"} width={"100%"} />
                      </div>
                    }
                  >
                    <MapFleetDashboard
                      tours={tours}
                      selectedTour={selectedTour}
                      setIsMapReady={setIsMapReady}
                    />
                  </Suspense>
                </div>
                <div
                  className={`step-info-dashboard-container ${
                    selectedTourID ? "open" : ""
                  }`}
                >
                  <div className="step-info-dashboard-container-inner">
                    <div className="step-info-header">
                      <span className="logo-container-feed">
                        <FontAwesomeIcon
                          icon={faGlobeEurope}
                          className="icon-step-detail"
                        />
                      </span>
                      <span className="step-info-desc">Étape en cours</span>
                      <span className="step-info-step-name">
                        {
                          /*
                            Si la tournée n'est pas commencé, on affiche qu'aucune étape n'est en cours de livraison.
                            Si non : On va vérifier qu'il y'a bien des étapes encore à livrer
                              Si oui : On affiche l'étape en cours (retourné par le back, la première étape non livré restante)
                              Si non : On affiche rien
                            Si non : On affiche "Tournée terminée"
                          */

                          selectedTour?.status === "CREATED"
                            ? "Aucune étape en cours pour cette tournée"
                            : selectedTour?.vehicule_and_datas
                                ?.steps_handled_left_to_deliver > 0
                            ? selectedTour.vehicule_and_datas?.current_step
                                ?.step_id
                              ? selectedTour?.vehicule_and_datas?.current_step
                                  ?.end_address?.name
                              : null
                            : "Tournée terminée"
                        }
                      </span>
                    </div>
                    <div className="step-info-progression">
                      <CircularProgressbarWithChildren
                        value={
                          ((selectedTour?.vehicule_and_datas?.steps?.filter(
                            (x) =>
                              x.status === "DELIVERED" ||
                              x.status === "PARTIALLY_DELIVERED" ||
                              x.status === "CANCELED"
                          )?.length ?? 0) /
                            selectedTour?.vehicule_and_datas?.steps?.length) *
                          100
                        }
                        styles={buildStyles({
                          stroke: "#d6d6d6",
                        })}
                      >
                        {/* Put any JSX content in here that you'd like. It'll be vertically and horizontally centered. */}
                        <div
                          className="step-info-circular-wrapper"
                          style={{ width: 40, fontSize: "1.2rem" }}
                        >
                          <strong>
                            {selectedTour?.vehicule_and_datas?.steps?.filter(
                              (x) =>
                                x.status === "DELIVERED" ||
                                x.status === "PARTIALLY_DELIVERED" ||
                                x.status === "CANCELED"
                            )?.length ?? 0}
                            /
                            {selectedTour?.vehicule_and_datas?.steps?.length ??
                              "00"}
                          </strong>
                        </div>
                        <div
                          style={{
                            fontSize: "11px",
                            marginTop: -5,
                            color: "#b5b5b5",
                          }}
                        >
                          étapes
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                    <a
                      className="step-info-call-to-action-wrapper"
                      href={`/tour/${selectedTour?.tour_id}`}
                    >
                      <span className="call-to-action">Aller à la tournée</span>
                      <span className="call-to-action-logo">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="icon-step-detail"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="feed-part">
              <div className="tour-header">
                <div
                  className="tour-header-left"
                  // onClick={() => {
                  //   // Permet de mock la réception d'un event du back
                  //   setFlux((_flux) => {
                  //     return [
                  //       {
                  //         _id: Math.random(0).toString(),
                  //         level: "INFO",
                  //         message: "Test",
                  //         type: "CREATE",
                  //         short_label: "STEP_STARTED",
                  //         creation_date: new Date().toISOString(),
                  //         client_id: 1,
                  //         vehicle_key: "ABCD",
                  //       },
                  //       ..._flux,
                  //     ];
                  //   });
                  // }}
                >
                  {isToday(new Date(date)) ? (
                    <>
                      <div className="tour-header-title">Flux en direct</div>
                      <div className="live-signal"></div>
                    </>
                  ) : (
                    <>
                      <div className="tour-header-title">
                        Flux du{" "}
                        {date.toLocaleDateString("fr-FR", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </div>
                    </>
                  )}
                </div>
                <div className="tour-header-right">
                  <select
                    className="tour-select"
                    disabled={flux?.length === 0}
                    onChange={(e) => setFluxStatus(e.target.value)}
                  >
                    <option value="all">Toutes</option>
                    <option value="step">Étapes</option>
                    <option value="reserve">Réserves</option>
                  </select>
                </div>
              </div>
              <div className="feed-div-container">
                <div className="feed-dashboard-container">
                  <Suspense
                    fallback={
                      <div>
                        <SkeletonLoader height={"100%"} width={"100%"} />
                      </div>
                    }
                  >
                    <FluxFleetDashboard
                      flux={flux}
                      isLoading={isLoading}
                      selectedTour={selectedTour}
                      fluxStatus={fluxStatus}
                    />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashboardClaimModal
        show={showClaiming}
        onHide={() => setShowClaiming(false)}
        date={date}
        base_url={BASE_URL}
        FetchClaimData={FetchClaimData}
        issues={issues}
      />
    </>
  );
};

export default HomeV2;
