import React, { useState, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import { Card, Accordion, useAccordionButton } from "react-bootstrap";

import "../App/App.scss";
import "./tourItemOptimization.scss";
import earth from "./earth.png";

import StepList from "../StepList/StepList";
import StepOptimization from "../StepOptimization/StepOptimization";
import StatusComponent from "../StatusComponent/StatusComponent";
import { useSelector } from "react-redux";

import Skeleton from "react-loading-skeleton";

const ContextAwareToggle = ({
  children,
  eventKey,
  callback,
  accordionContext,
  openedTour,
  setModalShow,
  setSelectedTour,
  tour,
}) => {
  // const { activeEventKey } = useContext(accordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (e) => {
    if (openedTour === eventKey) {
      setTimeout(() => {
        callback && callback(null);
      }, 100);
    } else {
      callback && callback(eventKey);
    }
  });

  const tourButtonClick = (eventKey) => {
    setSelectedTour(tour);
    setModalShow(true);
  };

  return (
    <div
      as={Card.Header}
      onClick={(e) =>
        e.target.className === "fleet-item-delete"
          ? tourButtonClick(eventKey)
          : decoratedOnClick(e)
      }
      className={"fleet-first-line-tour"}
    >
      {children}
    </div>
  );
};

const TourItemOptimization = (props) => {
  return (
    <div>
      <div
        className={"fleet-item-optimization"}
        style={{ borderLeft: `4px solid ${props.color}` }}
      >
        <ContextAwareToggle
          eventKey={`${props.tour.vehicule_key}`}
          accordionContext={props.accordionContext}
          id={`${props.tour.tour_id}`}
          callback={props.setOpenedTour}
          openedTour={props.openedTour}
          setModalShow={props.setModalShow}
          setSelectedTour={props.setSelectedTour}
          tour={props.tour}
        >
          <div className={"hw"}>
            <img
              src={earth}
              className={"img-illustration-fleet-2"}
              alt={"truck"}
            />
          </div>
          <div className={"fr"}>
            <span className={"fleet-item-title"}>
              Véhicule {props.tour.vehicule_key || <Skeleton />}{" "}
            </span>
            <span className={"fleet-item-desc"}>
              Immatriculé {props.tour.imat || "tour_key"}
            </span>
          </div>
          <div className={"hwe-opti"}>
            <span className="truck-capacity me-2">
              {props.tour.max_volume - props.tour.left_volume} L
            </span>
            <button className={"fleet-item-delete"}>
              <FontAwesomeIcon icon={faEllipsisV} className="fleet-icon" />
            </button>
          </div>
        </ContextAwareToggle>

        <Accordion.Collapse
          eventKey={`${props.tour.vehicule_key}`}
          className="fleet-item-collapsed"
        >
          <div className="fleet-item-steps-container-optimization">
            <div className="fleet-item-steps-container-inner">
              {props.tour?.steps?.length > 0 ? (
                <div className="fleet-item-step-optimization-container-tr">
                  {props.tour?.steps?.map((value, index) => {
                    return (
                      <StepOptimization
                        key={`step_key_${value.uuid}`}
                        step={value}
                        stepsNumber={index + 1}
                        bls={value.bls || []}
                        color={props.color}
                      />
                    );
                  })}
                </div>
              ) : props.isLoading ? (
                <p>loader</p>
              ) : (
                <div className={"fleet-item-step-no-item"}>
                  <p>Aucune étape n'est associée à cette tournée.</p>
                </div>
              )}
            </div>
          </div>
        </Accordion.Collapse>
      </div>
    </div>
  );
};

export default React.memo(TourItemOptimization);
