import React from "react";
import ContentLoader from "react-content-loader";

export const MyLoader = (props) => (
  <div className={"fleet-item"}>
    <ContentLoader
      speed={2}
      width={476}
      height={70}
      viewBox="0 0 476 70"
      backgroundColor="#f3f3f3"
      foregroundColor="#dedede"
      {...props}
    >
      <rect x="163" y="6" rx="5" ry="5" width="177" height="15" />
      <rect x="161" y="33" rx="5" ry="5" width="253" height="15" />
      <rect x="370" y="6" rx="5" ry="5" width="101" height="15" />
    </ContentLoader>
  </div>
);

export const MyStepLoader = (props) => (
  <div className={"fleet-item-step"}>
    <ContentLoader
      speed={2}
      width={476}
      height={70}
      viewBox="0 0 476 70"
      backgroundColor="#f3f3f3"
      foregroundColor="#dedede"
      {...props}
    >
      <circle cx="32" cy="32" r="32" />
      <rect x="163" y="6" rx="5" ry="5" width="177" height="15" />
      <rect x="161" y="33" rx="5" ry="5" width="253" height="15" />
      <rect x="370" y="6" rx="5" ry="5" width="101" height="15" />
    </ContentLoader>
  </div>
);

export const MyBLLoader = (props) => (
  <div className={"card card-loader-5"}>
    <div className={"card-header"}>
      <ContentLoader
        speed={0.5}
        width={473}
        height={24}
        viewBox="0 0 473 24"
        backgroundColor="#f3f3f3"
        foregroundColor="#dedede"
        {...props}
      >
        <rect x="83" y="3" rx="6" ry="6" width="352" height="17" />
        <rect x="11" y="-1" rx="5" ry="5" width="34" height="27" />
      </ContentLoader>
    </div>
  </div>
);

export default MyLoader;
