import React, { Component, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faWeightHanging,
} from "@fortawesome/free-solid-svg-icons";
import { AccordionContext } from "react-bootstrap";

import { Card, Badge, Accordion, useAccordionButton } from "react-bootstrap";

import "../App/App.scss";
import "./Step.css";
import box from "./box.png";
import inImg from "./StepAssets/in_v2.png";
import invalidImg from "./StepAssets/in.png";
import outImg from "./StepAssets/out.png";
import inOutImg from "./StepAssets/in_out_v2.png";

import { Bl } from "../Bl/Bl";
import StatusComponent from "../StatusComponent/StatusComponent";
import { setBlsForATour } from "../../reducer/Action";
import connect from "react-redux/es/connect/connect";
import { MyBLLoader } from "../Loader/MyLoader";

import pictoPhotoDisabled from "../Bl/BlAssets/photoDisabled.png";
import pictoPhoto from "../Bl/BlAssets/photo.png";
import pictoSign from "../Bl/BlAssets/sign.png";
import pictoSignDisabled from "../Bl/BlAssets/signDisabled.png";
import pictoCommentDisabled from "./StepAssets/icon_comment_disabled.png";
import pictoComment from "./StepAssets/icon_comment.png";
import informationPicto from "./StepAssets/information.png";

function CustomToggle({ children, eventKey, callback }) {
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  //const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      as={Card.Header}
      className={"card-header-no-css"}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  );
}

export class Step extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      detailed: false,
      statusColorClass: "",
      isLoading: false,
      localBls: null,
    };
  }
  static contextType = AccordionContext;

  componentDidMount() {
    if (this.props.step.is_late) {
      this.setState({
        statusColorClass: "late",
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.exactMatch !== this.props.exactMatch) {
      if (
        this.props.exactMatch &&
        this.props.step.step_id === this.props.exactMatch
      ) {
        this.handleStepCollapse(null, this.props.step.step_id, null);
      }
    }
  }

  handleStepCollapse = (e, id, tour_id) => {
    if (this.state.localBls == null && !this.state.isLoading) {
      this.setState({
        isLoading: true,
      });

      const url = new URL(
        this.props.BASE_URL.concat("/delivery-details/by-step-id/", id)
      );
      const token = localStorage.getItem("fleetToken");

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", token);
      let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

      console.log(
        "GET request @ ",
        this.props.BASE_URL.concat("/steps/getBls/", id)
      );
      fetch(url, myInit)
        .then((response) => {
          if (response.ok) {
            response.json().then((result) => {
              if (result.status === "Success") {
                // this.props.tour.steps = result.steps;
                if (result.deliveryDetails) {
                  this.setState({
                    localBls: result.deliveryDetails,
                  });
                  //this.props.setBlsForATour(tour_id, id, result.steps.bls);
                }
                this.setState({
                  isLoading: false,
                });
              }
            });
          } else {
            this.setState({
              isLoading: false,
            });
            console.log("Mauvaise réponse du réseau");
            response.json().then(function (result) {
              console.log("result", result);
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          console.log(
            "Il y a eu un problème avec l'opération fetch: " + error.message
          );
        });
    }
  };

  render() {
    let imgComponent = null;
    let commentComponent = null;
    let pictoComponent = null;

    if (this.props.step.is_handled === 0) {
      imgComponent = (
        <img
          src={invalidImg}
          className={"img-illustration-fleet-step"}
          alt={"truck"}
        />
      );
    } else {
      if (this.props.step?.delivery_type === "IN") {
        imgComponent = (
          <img
            src={inImg}
            className={"img-illustration-fleet-step"}
            alt={"truck"}
          />
        );
      } else if (this.props.step?.delivery_type === "OUT") {
        imgComponent = (
          <img
            src={outImg}
            className={"img-illustration-fleet-step"}
            alt={"truck"}
          />
        );
      } else if (this.props.step?.delivery_type === "IN/OUT") {
        imgComponent = (
          <img
            src={inOutImg}
            className={"img-illustration-fleet-step"}
            alt={"truck"}
          />
        );
      } else {
        imgComponent = (
          <img
            src={box}
            className={"img-illustration-fleet-step"}
            alt={"truck"}
          />
        );
      }
    }
    if (this.props.step?.is_present) {
      pictoComponent = (
        <img
          src={
            this.props.step.status === "STARTED" ? pictoSignDisabled : pictoSign
          }
          className={"img-illustration-fleet-bl-sign"}
          alt={"picto_photo"}
        />
      );
    } else {
      pictoComponent = (
        <img
          src={
            this.props.step.status === "STARTED"
              ? pictoPhotoDisabled
              : pictoPhoto
          }
          className={"img-illustration-fleet-bl-sign"}
          alt={"picto_photo_disabled"}
        />
      );
    }

    if (this.props.step.comments) {
      commentComponent = (
        <img
          src={
            this.props.step.status === "STARTED"
              ? pictoCommentDisabled
              : pictoComment
          }
          className={"img-illustration-fleet-bl-sign me-1"}
          alt={"picto_photo"}
        />
      );
    }

    let stepInvalidClassName = "";

    if (this.props.step.is_handled === 0) {
      if (this.props.step.does_contain_bl_for_CAL === 1) {
        stepInvalidClassName = "invalid-CAL";
      } else {
        stepInvalidClassName = "invalid";
      }
    }

    return (
      <div>
        <CustomToggle eventKey={"bl-list" + this.props.step.step_id}>
          <div
            className={`fleet-item-step ${stepInvalidClassName} ${
              this.props.step.status === "STARTED" ? "active" : ""
            } ${this.state.statusColorClass} ${
              this.props.exactMatch ? "search-match" : ""
            }`}
            onClick={(e) => {
              this.handleStepCollapse(
                e,
                this.props.step.step_id,
                this.props.step.tour_id
              );
            }}
          >
            <div className={"fleet-first-line"}>
              <div className={"hw"}>{imgComponent}</div>
              <div className={"fr"}>
                <span className={"fleet-item-title"}>
                  {this.props.step.end_address?.name || "Étape invalide"}{" "}
                  <StatusComponent
                    status={this.props.step.status}
                    statusType={"step"}
                    is_handled={this.props.step.is_handled}
                  />
                </span>
                <span className={"fleet-item-desc"}>
                  {!this.props.step.end_address?.address_number &&
                  !this.props.step.end_address?.street &&
                  !this.props.step.end_address?.city &&
                  !this.props.step.end_address?.zipcode
                    ? "Aucune information pour cette étape"
                    : null}
                  {this.props.step.end_address?.address_number}{" "}
                  {this.props.step.end_address?.street}{" "}
                  {this.props.step.end_address?.city}
                  {this.props.step.end_address?.zipcode
                    ? `, ` + this.props.step.end_address?.zipcode
                    : null}
                </span>
                <span className={"fleet-item-arrival"}>
                  Attendu au plus tard <FontAwesomeIcon icon={faCaretRight} />{" "}
                  {this.props.step.estimated_delivery
                    ? new Date(
                        this.props.step.estimated_delivery
                      ).toLocaleDateString("fr-FR", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })
                    : "Date de livraison inconnue"}{" "}
                  <FontAwesomeIcon icon={faWeightHanging} />{" "}
                  {this.props.step.total_weight
                    ? `${parseInt(this.props.step.total_weight)}kg`
                    : "Poids non renseigné"}
                </span>
              </div>
              <div className={"hwe"}>
                <div className="hwe-child-wrapper">
                  <span>{this.props.stepsNumber}</span>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {commentComponent}
                    {pictoComponent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={"bl-list" + this.props.step.step_id}>
          <Card.Body>
            <div className={"fleet-item-bl"}>
              <div className="fleet-item-information-container">
                <img src={informationPicto} alt={"inmagei"} />
                <span>
                  {this.props.step.comments
                    ? this.props.step.comments
                    : "Pas de commentaire de livraison pour cette étape."}
                </span>
              </div>

              <div>
                <h5>
                  Liste des BL(s){" "}
                  <Badge bg="secondary">
                    {this.state?.localBls ? this.state?.localBls.length : 0}
                  </Badge>
                </h5>
              </div>

              <div className={"fleet-item-bl-list-item"}>
                {this.state.isLoading ? (
                  <div className={"loader-section card-header-no-css"}>
                    <MyBLLoader />
                    <MyBLLoader />
                  </div>
                ) : (
                  <div>
                    {this.props?.step.step_id &&
                      this.state?.localBls?.length > 0 &&
                      this.state?.localBls?.map((value, index) => {
                        return (
                          <Bl
                            key={`bl_tourid_${value?.delivery_detail_id}_${value?.step_id}`}
                            bl={value}
                            items={value.items}
                            activeKey={index}
                          />
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    BASE_URL: state.BASE_URL,
  };
};

const mapDispatchToProps = { setBlsForATour };

export default connect(mapStateToProps, mapDispatchToProps)(Step);
