import React, { Component } from "react";

import "../App/App.scss";
import "./StatusComponent.css";

export class StatusComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusColorClass: null,
      statusText: "",
    };
  }

  componentDidMount() {
    this.computeStatus();
  }

  computeStatus() {
    let newStatusText = "";
    let newColorClass = "";

    switch (this.props.statusType) {
      case "tour":
        switch (this.props.status) {
          case "CREATED":
            newStatusText = "Créée";
            newColorClass = "ok";
            break;
          case "STARTED":
            newStatusText = "Débutée";
            newColorClass = "ok";
            break;
          case "DONE":
            newStatusText = "Terminée";
            newColorClass = "ok";
            break;
          case "LATE":
            newStatusText = "En retard";
            newColorClass = "late";
            break;
          case "ISSUE":
            newStatusText = "Problème";
            newColorClass = "issue";
            break;
          default:
            newStatusText = "default";
            newColorClass = "issue";
        }
        break;
      case "step":
        if (this.props?.is_handled === 0) {
          return this.setState({
            statusText: "Non géré",
            statusColorClass: "issue",
          });
        }

        switch (this.props.status) {
          case "CREATED":
            newStatusText = "Planifiée";
            newColorClass = "ok";
            break;
          case "STARTED":
            newStatusText = "Livraison en cours";
            newColorClass = "ok";
            break;
          case "DELIVERED":
            newStatusText = "Livrée";
            newColorClass = "ok";
            break;
          case "PARTIALLY_DELIVERED":
            newStatusText = "Partiellement délivrée";
            newColorClass = "issue";
            break;
          case "CANCELED":
            newStatusText = "Non livrée";
            newColorClass = "issue";
            break;
          case "LATE":
            newStatusText = "En retard";
            newColorClass = "late";
            break;
          default:
            newStatusText = "default";
            newColorClass = "issue";
        }
        break;
      case "bl":
        switch (this.props.status) {
          case "CREATED":
            newStatusText = "Créée";
            newColorClass = "ok";
            break;
          case "STARTED":
            newStatusText = "Débuté";
            newColorClass = "ok";
            break;
          case "DONE":
            newStatusText = "Livré";
            newColorClass = "ok";
            break;
          case "CANCELED":
            newStatusText = "Non livré";
            newColorClass = "late";
            break;
          case "RETURNED":
            newStatusText = "retourné";
            newColorClass = "issue";
            break;
          default:
            newStatusText = "default";
            newColorClass = "issue";
        }
        break;
      default:
        newStatusText = "default";
        newColorClass = "issue";
    }

    if (this.props.isLate) {
      this.setState({
        statusText: newStatusText,
        statusColorClass: "late",
      });
    } else {
      this.setState({
        statusText: newStatusText,
        statusColorClass: newColorClass,
      });
    }
  }

  render() {
    return (
      <span className={"fleet-item-status " + this.state.statusColorClass}>
        {this.state.statusText}
      </span>
    );
  }
}

export default StatusComponent;
