import React, { Component } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReserveBoardList from "./ReserveBoardList/ReserveBoardList";

let timerFetchReserves = null;
export class ReserveBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentSize: props.componentSize,
      reserves: null,
      sumCount: 0,
    };
    this._isMounted = false;
  }

  fetchReserves = () => {
    const url = new URL(this.props.BASE_URL.concat("/reserve"));
    const token = localStorage.getItem("fleetToken");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

    console.log("GET request @ ", this.props.BASE_URL.concat("/reserve"));
    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Success") {
              this._isMounted &&
                this.setState({
                  reserves: result.reserves,
                });
            }
          });
        } else {
          console.log("Mauvaise réponse du réseau");
          response.json().then(function (result) {
            console.log("result", result);
          });
        }
      })
      .catch(function (error) {
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchReserves();
    timerFetchReserves = setInterval(() => {
      this.fetchReserves();
    }, 10000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.reserves !== prevState.reserves) {
      this.setState({
        sumCount: this?.state?.reserves?.length,
      });
    }
  }

  componentWillUnmount() {
    // Lors du déchargement de la page, on de-register le setInterval qui va fetch les réserves.
    this._isMounted = false;
    clearInterval(timerFetchReserves);
  }

  render() {
    return (
      <Col xs={this.state.componentSize}>
        <div className="card-fleet">
          <h4>
            Réserves <Badge bg="secondary">{this.state.sumCount}</Badge>
          </h4>

          <PerfectScrollbar>
            <div>
              <div className={"card-fleet-header-2"}></div>
              <div className={"issues-return-container-wrapper"}>
                <ReserveBoardList
                  list={this.state.reserves}
                  status={"annulé"}
                />
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </Col>
    );
  }
}

export default ReserveBoard;
