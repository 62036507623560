import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faSearch,
  faExclamationCircle,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Badge,
  Collapse,
} from "react-bootstrap";

import "../App/App.scss";
import "../_pages/Vehicle/Vehicle.css";
import truck from "./truck.png";
import { setVehiclePin, setVehicles } from "../../reducer/Action";
import connect from "react-redux/es/connect/connect";

export class VehicleItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pinCode: "",
      doneTypingInterval: 2000,
      typingTimer: null,
      error: "",
      errorClass: "none",
      errorStatus: false,
      isOpen: false,
    };
  }

  doneTyping = (id) => {
    const url = new URL(this.props.BASE_URL + "/vehicules/" + id);
    const token = localStorage.getItem("fleetToken");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({
        pin: this.state.pinCode,
      }),
      cache: "no-cache",
    };

    console.log("PUT request @ ", this.props.BASE_URL + "/vehicules/" + id);

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Success") {
              if (!result.isVehiculeUpdated) {
                this.setState({
                  error:
                    "Une erreur est survenue lors de la mise à jour du pin.",
                  errorClass: "block",
                  errorStatus: true,
                });
              }
              this.props.setVehiclePin(id, this.state.pinCode);
            }
          });
        } else {
          console.log("Mauvaise réponse du réseau");
          response.json().then((result) => {
            this.setState({
              error: "Une erreur est survenue lors de la mise à jour du pin.",
              errorClass: "block",
              errorStatus: true,
            });
          });
        }
      })
      .catch((error) => {
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
        this.setState({
          error:
            "Une erreur est survenue lors de la mise à jour du pin.: Failed to fetch",
          errorClass: "block",
          errorStatus: true,
        });
      });
  };

  handlePinChange(e, id) {
    const pin = e.target.value;
    let errorMessage = "";
    let error = false;

    clearTimeout(this.state.typingTimer);

    this.setState({
      pinCode: pin,
    });

    if (pin.length !== 6) {
      error = true;
      errorMessage = "Le code pin doit avoir une longueur de 6 caractères.";
    }

    if (!error) {
      this.setState({
        typingTimer: setTimeout(() => {
          this.doneTyping(id);
        }, 2000),
        error: "",
        errorClass: "none",
        errorStatus: false,
      });
    } else {
      this.setState({
        error: errorMessage,
        errorClass: "block",
        errorStatus: true,
      });
    }
  }

  render() {
    let classNameInput =
      "input-generic-fleet-searchbar form-control text-center ";
    classNameInput += this.state.error ? "error" : "";
    return (
      <div key={this.props.vehicle.vehicule_id} className={"fleet-item"}>
        <div
          className={"fleet-first-line-vehicle"}
          onClick={() =>
            this.setState((state, props) => ({ isOpen: !state.isOpen }))
          }
          aria-controls="example-collapse-text"
          aria-expanded={this.state.isOpen}
        >
          <div className={"hw"}>
            <img
              src={truck}
              className={"img-illustration-fleet"}
              alt={"truck"}
            />
          </div>
          <div className={"fr"}>
            <span className={"fleet-item-title"}>
              Véhicule immatriculé{" "}
              {this.props.vehicle.imat ? this.props.vehicle.imat : "XXXXXXX"}
            </span>
            <span className={"fleet-item-desc"}>
              Clé véhicule {this.props.vehicle.vehicule_key}{" "}
            </span>
          </div>
          <div className={"hwe"}>
            <button className={"fleet-item-delete"} style={{ display: "none" }}>
              <FontAwesomeIcon icon={faInfo} />
            </button>
          </div>
        </div>

        <Collapse
          in={this.state.isOpen}
          className="fleet-item-collapsed "
          id={"collapse-fleet-item-vehicle" + this.props.vehicle.vehicule_id}
        >
          <div className="fleet-item-steps-container">
            <div className={"fleet-other-item"}>
              <Container>
                <Row>
                  <Col>
                    <div className="flex-item-input">
                      <div className="flex-item-input-label">Code PIN</div>
                      <div className="flex-item-input-result">
                        <Form.Group
                          className={"form-control-vehicle"}
                          controlId={
                            "formBasicEmail" + this.props.vehicle.vehicule_key
                          }
                        >
                          <Form.Control
                            type="text"
                            placeholder="123456"
                            className={classNameInput}
                            onChange={(e) =>
                              this.handlePinChange(
                                e,
                                this.props.vehicle.vehicule_id
                              )
                            }
                            maxLength={"6"}
                            defaultValue={this.props.vehicle.pin}
                          />
                          <Form.Text className="text-muted text-muted-vehicle">
                            Nombre à 6 chiffres utilisé pour la connexion des
                            livreurs.
                          </Form.Text>
                          <span
                            className="error-message"
                            style={{ display: this.state.errorClass }}
                          >
                            <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                            {this.state.error}
                          </span>
                        </Form.Group>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    BASE_URL: state.BASE_URL,
    vehicles: state.vehicles,
  };
};

const mapDispatchToProps = { setVehiclePin };

export default connect(mapStateToProps, mapDispatchToProps)(VehicleItem);
