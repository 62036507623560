import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  Suspense,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faCircleInfo,
  faCirclePlus,
  faDiagramProject,
  faGear,
  faNetworkWired,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import noDataIllu from "../../../assets/images/illustration/undraw_map_re_60yf@2x.png";
import { Link } from "react-router-dom";
import TourItemFleetManagement from "../../pages/FleetManagement/tour-item-fleet-management/tour-item-fleet-management";
import TourItemDashboardLoader from "../../pages/Dashboard/tour-item-dashboard-loader/tour-item-dashboard-loader";
import DashboardClaimModal from "../../pages/Dashboard/dashboard-claim-modal/dashboard-claim-modal";
import { Button, Spinner } from "react-bootstrap";
import SimpleBarReact from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import ModalAddVehicule from "./modal-management/modal-add-truck";
import ModalConfig from "./modal-management/modal-config";
import ModalAddPlatform from "./modal-management/modal-add-platform";
import { tour_status } from "../../../constants/tour_status";

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import "../../App/App.scss";
import "./FleetManagement.scss";
import { io } from "socket.io-client";
import SkeletonLoader from "tiny-skeleton-loader-react";

import { useDispatch, useSelector } from "react-redux";
import { setNavWidth } from "../../../reducer/Action";
import PlatformList from "../../pages/FleetManagement/platform-management";
import ModalOnBoarding from "./modal-management/modal-onboarding";

registerLocale("fr", fr);

const MapFleetDashboard = React.lazy(() =>
  import(
    "../../pages/FleetManagement/map-fleet-management/map-fleet-management"
  )
);

const FleetManagement = (props) => {
  /******
   *
   * States
   *
   ******/

  const [vehicles, setVehicles] = useState([]);
  const [vehiclesConfig, setVehiclesConfig] = useState([]);
  const [platforms, setPlatforms] = useState([]);

  const [show, setShow] = useState(false);
  const [date, setDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const [isVehiclesLoading, setIsVehiclesLoading] = useState(false);
  const [isPlatformsLoading, setIsPlatformsLoading] = useState([]);

  const [isDownloadingResume, setIsDownloadingResume] = useState(false);

  const [selectedTourID, setSelectedTourID] = useState(null);
  const [selectedTour, setSelectedTour] = useState(null);
  const [tourStatus, setTourStatus] = useState("all");
  const [fluxStatus, setFluxStatus] = useState("all");
  const [modalShow, setModalShow] = useState(false);
  const [modalShowTwo, setModalShowTwo] = useState(false);
  const [modalHelp, setModalHelp] = useState(false);
  const [modalShowAddPlatform, setModalShowAddPlatform] = useState(false);
  const [isMapReady, setIsMapReady] = useState(false);
  const [isMapUpdating, setIsMapUpdating] = useState(false);
  const [isFleetEditing, setIsFleetEditing] = useState(false);

  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const BASE_URL = useSelector((state) => state.BASE_URL);
  const ref = useRef();

  /******
   *
   * functions
   *
   ******/

  const isFiltering = useCallback(() => {
    return tourStatus !== "all";
  }, [tourStatus]);

  // filter all the tours by the status
  const filteringFunction = useCallback(() => {
    if (!vehicles) {
      return [];
    }

    if (!isFiltering()) {
      return vehicles;
    }

    return vehicles.filter((x) => {
      return x.status?.toUpperCase() === tourStatus?.toUpperCase();
    });
  }, [vehicles, tourStatus, isFiltering]);

  const getPlatforms = useCallback(() => {
    const url = new URL(BASE_URL + "/starting-platform");
    const token = localStorage.getItem("fleetToken");

    setIsPlatformsLoading(true);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

    console.log("GET request @ ", BASE_URL + "/starting-platform");

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Success") {
              setIsPlatformsLoading(false);
              setPlatforms(result.startingPlatforms);
            }
          });
        } else {
          setIsPlatformsLoading(false);
          console.log("Mauvaise réponse du réseau");
          response.json().then(function (result) {
            console.log("result", result);
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  }, [BASE_URL]);

  const getVehicles = useCallback(() => {
    const url = new URL(BASE_URL + "/vehicules");
    const token = localStorage.getItem("fleetToken");

    setIsVehiclesLoading(true);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = { method: "GET", headers: myHeaders, cache: "no-cache" };

    console.log("GET request @ ", BASE_URL + "/vehicules");

    fetch(url, myInit)
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Success") {
              setIsVehiclesLoading(false);
              setVehicles(result.vehicules);
              setVehiclesConfig({
                can_create_tanker_trucks: result.canCreateTankerTrucks,
                can_create_trucks: result.canCreateTrucks,
              });
            }
          });
        } else {
          setIsVehiclesLoading(false);
          console.log("Mauvaise réponse du réseau");
          response.json().then(function (result) {
            console.log("result", result);
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setIsVehiclesLoading(false);
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  }, [BASE_URL]);

  const toggleActivation = async (_id) => {
    if (!_id) return;
    const url = new URL(
      BASE_URL + "/vehicules/toggle-for-optimization/" + _id
    );
    const token = localStorage.getItem("fleetToken");

    setIsLoading(true);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = {
      method: "PUT",
      headers: myHeaders,
      cache: "no-cache",
    };

    console.log(
      "PUT request @ ",
      BASE_URL + "/vehicules/toggle-for-optimization/" + _id
    );

    return fetch(url, myInit)
      .then((response) => response.json())
      .catch((error) => {
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
        return false;
      });
  };

  /******
   *
   * handlers
   *
   ******/

  function switchStatut(_id) {
    const newVehicles = vehicles.map((vehicle) => {
      if (vehicle.vehicule_id === _id) {
        vehicle.is_disabled = vehicle.is_disabled === 0 ? 1 : 0;
      }
      return vehicle;
    });
    setVehicles(newVehicles);
  }

  const handleToggleVehicleForOptimization = (_id) => {
    toggleActivation(_id).then((result) => {
      if (result) {
        if (result.isVehiculeUpdated === true) {
          switchStatut(_id)
        }
      } else {
        fetch(result)
          .then((response) => response.json())
          .catch((error) => {
            console.log(
              "Il y a eu un problème avec l'opération fetch: " + error.message
            );
          });
        switchStatut(_id)
      }
      setIsLoading(false);
    });
  };

  const handleEditVehicle = (vehicle) => {
    if (!selectedVehicle) {
      setSelectedVehicle(vehicle);
      setModalShow(true);
    } else {
      setSelectedVehicle(null);
      setModalShow(false);
    }
  };

  /******
   *
   * Effects
   *
   ******/

  /*****
   * will set the selected tour in order to use in the map detail when the user click on a tour
   */

  // simple-bar recalculate width and height
  useEffect(() => {
    ref.current.recalculate();
  });

  // Did mount effect
  useEffect(() => {
    getPlatforms();
    getVehicles();
    setSelectedTourID(null);
    setTourStatus("all");
  }, [BASE_URL, getPlatforms, getVehicles]);

  /******
   *
   * Return
   *
   ******/

  return (
    <>
      <div className={"page-wrapper"}>
        <div className={"header-fleet"}>
          <div className="header-fleet-inner">
            <div className="pe-2">
              <h3>Définition de votre flotte</h3>
              <h4>Créez, modifiez ou désactivez vos camions pour l'optimisation.</h4>
            </div>
            <div className="fleet-management-tour-header">
              <Button
                variant=""
                className="btn-fleet-header"
                style={{ marginRight: "15px" }}
                onClick={() => setModalHelp(true)}
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ fontSize: 14 }}
                  color="#757476"
                />
              </Button>
              <Button
                variant=""
                className="btn-fleet-header"
                style={{ marginRight: "15px" }}
                onClick={() => setModalShowTwo(true)}
                disabled={
                  isLoading || isDownloadingResume || vehicles?.length === 0 || isFleetEditing
                }
              >
                <FontAwesomeIcon
                  icon={faGear}
                  style={{ fontSize: 14 }}
                  color="#757476"
                />
              </Button>
              <Link to="/optimization">
                <span className={"btn-fleet-wrapper"}>
                  <Button variant="" className="btn-fleet-header">
                    <FontAwesomeIcon icon={faAngleLeft} className="btn-icon" />
                    Retour à l'optimisation
                  </Button>
                </span>
              </Link>
            </div>
          </div>
          {/* <img
            src={bgdeco2}
            alt="background_decoration_fleet"
            className="bg-deco-fleet"
          /> */}
        </div>
        <div className="fleet-management-content-container">
          <div className="fleet-management-content-left-part">
            <div className="fleet-management-tour-header">
              <div className="tour-header-left">
                <span className="tour-header-title">
                  Liste de vos véhicules
                </span>
                <span className="tour-header-number badge">
                  {vehicles?.length ?? 0}
                </span>
              </div>
              <div className="tour-header-right">
                <Button
                  variant=""
                  onClick={() => setModalShow(true)}
                  className="btn-add-truck"
                  disabled={isLoading || isFleetEditing}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="icon-rounded-plus"
                  />
                  Ajouter un véhicule
                </Button>
                <Button
                  variant=""
                  onClick={() => setIsFleetEditing(!isFleetEditing)}
                  className="btn-add-truck ms-2"
                  disabled={isLoading}
                >
                  <FontAwesomeIcon
                    icon={faDiagramProject}
                    className="icon-rounded-plus"
                  />
                  Gestion de flotte
                </Button>
              </div>
            </div>
            <div className="tours-container">
              <div className="wrapper">
                <SimpleBarReact
                  forceVisible="y"
                  ref={ref}
                  style={{
                    maxHeight: "100%",
                    width: "100%",
                    padding: "10px 20px 10px 0px",
                  }}
                >
                  {vehicles?.length > 0 ? (
                    <>
                      {filteringFunction()?.length > 0 ? (
                        filteringFunction()?.map((vehicle, vehicle_index) => {
                          return (
                            <TourItemFleetManagement
                              key={`tour_${vehicle?.vehicule_id}`}
                              selectedTourID={selectedTourID}
                              handleEditVehicle={handleEditVehicle}
                              vehicle={vehicle}
                              isMapUpdating={isMapUpdating}
                              isMapReady={isMapReady}
                              isFleetEditing={isFleetEditing}
                              handleToggleVehicleForOptimization={handleToggleVehicleForOptimization}
                            />
                          );
                        })
                      ) : (
                        <div className="no-tour-container">
                          <img
                            src={noDataIllu}
                            alt="background_decoration_fleet"
                            className="no-tour-img"
                          />
                          <span className="no-tour-title">
                            Aucun véhicule en tournée avec
                            <br /> le filtre sélectionné
                          </span>
                          <span className="no-tour-text">
                            Aucun véhicule n'est en tournée pour la date du{" "}
                            {date.toLocaleDateString("fr-FR", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}{" "}
                            avec le statut{" "}
                            <strong>
                              {
                                tour_status?.properties[tourStatus]
                                  ?.display_name
                              }
                            </strong>
                            .
                          </span>
                        </div>
                      )}
                    </>
                  ) : isVehiclesLoading ? (
                    <>
                      <TourItemDashboardLoader />
                      <TourItemDashboardLoader />
                      <TourItemDashboardLoader />
                      <TourItemDashboardLoader />
                      <TourItemDashboardLoader />
                    </>
                  ) : (
                    <div className="no-tour-container">
                      <img
                        src={noDataIllu}
                        alt="background_decoration_fleet"
                        className="no-tour-img"
                      />
                      {/* <div
                        style={{ margin: "0 auto" }}
                        className="no-tour-img"
                        ref={animBox}
                      ></div> */}
                      <span className="no-tour-title">
                        Aucun véhicule défini.
                      </span>
                      <span className="no-tour-text">
                        Vous devez définir un véhicule pour le voir ici.
                      </span>
                    </div>
                  )}
                </SimpleBarReact>
              </div>
            </div>
          </div>
          <div className="fleet-management-content-right-part">
            <div className="fleet-management-map-part">
              <div className="map-div-container">
                <div className="map-div-header d-flex justify-content-between align-items-center">
                  <div className="map-header-div-one">
                    <span className="map-header-title">
                      Plateformes de départ
                    </span>
                    <p className="map-header-subtitle">
                      Définissez simplement vos différentes plateformes de
                      départ
                    </p>
                  </div>
                  <span className="map-header-number badge">
                    {platforms?.length ?? 0}
                  </span>
                </div>
                <div className="map-dashboard-container">
                  <Suspense
                    fallback={
                      <div className="map-wrapper">
                        <SkeletonLoader height={"100%"} width={"100%"} />
                      </div>
                    }
                  >
                    <MapFleetDashboard
                      tours={platforms}
                      selectedTour={selectedTour}
                      setIsMapReady={setIsMapReady}
                    />
                  </Suspense>
                </div>
                <div className="platform-container">
                  <PlatformList
                    platforms={platforms}
                    setModalShowAddPlatform={setModalShowAddPlatform}
                    isFleetEditing={isFleetEditing}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalAddVehicule
        show={modalShow}
        onHide={(vehicle_added) => {
          setModalShow(false);
          setSelectedVehicle(null);
          if (vehicle_added) {
            getVehicles();
          }
        }}
        platforms={platforms}
        selectedVehicle={selectedVehicle}
        BASE_URL={BASE_URL}
        can_create_trucks={vehiclesConfig?.can_create_trucks}
        can_create_tanker_trucks={vehiclesConfig?.can_create_tanker_trucks}
      />
      <ModalConfig
        show={modalShowTwo}
        onHide={() => setModalShowTwo(false)}
        BASE_URL={BASE_URL}
      />
      <ModalOnBoarding show={modalHelp} onHide={() => setModalHelp(false)} />
      <ModalAddPlatform
        show={modalShowAddPlatform}
        setModalShowAddPlatform={setModalShowAddPlatform}
        BASE_URL={BASE_URL}
        onHide={(plateform_added) => {
          setModalShowAddPlatform(false);
          if (plateform_added) {
            getPlatforms();
          }
        }}
      />
    </>
  );
};

export default FleetManagement;
