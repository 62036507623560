import React, { useEffect, useCallback } from "react";
import { Switch, Route, Link, useLocation, Redirect } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faClipboard,
  faTruck,
  faGlobeEurope,
  faQuestion,
  faFileImport,
  faFileInvoiceDollar,
  faProjectDiagram,
  faChevronLeft,
  faBroadcastTower,
} from "@fortawesome/free-solid-svg-icons";

import fleetBrand from "./FleetWhite.png";

import { Container, Row, Col } from "react-bootstrap";
import "./App.scss";
import Login from "../Login/Login";
import Home from "../Home/Home";
import HomeV2 from "../_pages/HomeV2/Home";
import FleetManagement from "../_pages/FleetManagement/FleetManagement";
import Tour from "../_pages/Tour/Tour";
import Vehicle from "../_pages/Vehicle/Vehicle";
import Admin from "../_pages/Admin/Admin";
import Restriction from "../_pages/Restriction/Restriction";
import Forgot from "../Forgot/Forgot.js";
import ResetPassword from "../Reset-password/Reset-password.js";
import { AddVehicle } from "../_pages/AddVehicle/AddVehicle";
import PrivateRoute, { checkAuth } from "../PrivateRoute/PrivateRoute";
import AddFile from "../_pages/AddFile/AddFile"; // Tell webpack this JS file uses this image
import Optimization from "../_pages/Optimization/Optimization"; // Tell webpack this JS file uses this image
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { logout, setConfiguration, setNavWidth } from "../../reducer/Action";
import jwt_decode from "jwt-decode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function App() {
  const config = useSelector((state) => state.config);
  const nav_width = useSelector((state) => state.nav_width);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();

  useEffect(() => {
    if (dispatch) {
      const token = localStorage.getItem("fleetToken");
      //decode the token and store it in state
      let decoded = token ? jwt_decode(token) : null;

      if (decoded) {
        if (decoded.user?.version !== "1.0.0") {
          logoutAction();
        }

        dispatch(
          setConfiguration({
            is_admin: decoded?.user?.is_admin === 1 ? true : false,
            can_upload_with_excel:
              decoded?.user?.can_upload_with_excel === 1 ? true : false,
            can_access_dashboard_v2:
              decoded?.user?.can_access_dashboard_v2 === 1 ? true : false,
            fleet_optimization:
              decoded?.user?.fleet_optimization === 1 ? true : false,
            logo_url: decoded?.user?.logo_url,
          })
        );
      }
    }
  }, [dispatch]);

  // useEffect(() => {
  //   console.log("changed :", config)
  // }, [config])

  const clientId = parseInt(localStorage.getItem("fleetClientId"));

  function logoutAction(e) {
    localStorage.removeItem("fleetToken");
    localStorage.removeItem("fleetClientId");
    dispatch(logout());

    history.push("/login");
  }

  function CollapseNavbarToggle() {
    dispatch(setNavWidth(nav_width === "expanded" ? "collapsed" : "expanded"));
  }

  const handleDrawerLogic = useCallback(() => {
    const clientWidth =
      window.innerWidth && document.documentElement.clientWidth
        ? Math.min(window.innerWidth, document.documentElement.clientWidth)
        : window.innerWidth ||
          document.documentElement.clientWidth ||
          document.getElementsByTagName("body")[0].clientWidth;

    if (clientWidth < 1250 && nav_width === "expanded") {
      dispatch(setNavWidth("collapsed"));
    } else if (clientWidth >= 1250 && nav_width === "collapsed") {
      dispatch(setNavWidth("expanded"));
    }
  }, [nav_width, dispatch]);

  useEffect(() => {
    if (window) {
      window.addEventListener("load", handleDrawerLogic);
      window.addEventListener("resize", handleDrawerLogic);
    }
    return () => {
      window.removeEventListener("load", handleDrawerLogic);
      window.removeEventListener("resize", handleDrawerLogic);
    };
  }, [handleDrawerLogic]);

  return (
    <div className={"App-root"}>
      <div className="App-status-bar "></div>
      <div className="App">
        {checkAuth() ? (
          <nav
            className={`App-nav ${nav_width !== "expanded" ? "collapsed" : ""}`}
          >
            <button
              onClick={() => CollapseNavbarToggle()}
              className="collapse-toggle"
            >
              <FontAwesomeIcon icon={faChevronLeft} className="icon" />
            </button>
            <div className="App-nav-wrapper">
              <div
                className="brand-logo-fleet-client"
                style={{ display: clientId ? "block" : "none" }}
              >
                <Link to={config?.can_access_dashboard_v2 ? "/" : "/vehicle"}>
                  <img src={fleetBrand} alt={"fleet-logo"} />
                </Link>
              </div>
              <div className="nav-list">
                <ul>
                  {config?.can_access_dashboard_v2 ? (
                    <li
                      className={`${
                        location?.pathname === "/" ? "active" : ""
                      }`}
                    >
                      <Link to="/">
                        <FontAwesomeIcon icon={faBroadcastTower} />{" "}
                        {nav_width === "expanded" ? (
                          <>
                            <span>Tour de contrôle</span>
                            {/* <span className="beta-tag">NOUVEAU</span> */}
                          </>
                        ) : null}
                      </Link>
                    </li>
                  ) : null}
                  {config?.fleet_optimization && (
                    <li
                      className={`${
                        location?.pathname === "/optimization" ? "active" : ""
                      }`}
                    >
                      <Link to="/optimization">
                        <FontAwesomeIcon icon={faProjectDiagram} />{" "}
                        {nav_width === "expanded" ? (
                          <>
                            <span>Optimisation</span>
                            <span className="beta-tag">BETA</span>
                          </>
                        ) : null}
                      </Link>
                    </li>
                  )}

                  {/* <li
                    className={`${location?.pathname === "/" ? "active" : ""}`}
                  >
                    <Link to={config?.can_access_dashboard_v2 ? "/" : "/vehicle"}>
                      <FontAwesomeIcon icon={faChartBar} />
                      {nav_width === "expanded" ? "Dashboard" : null}
                    </Link>
                  </li> */}

                  {!config?.fleet_optimization ? (
                    <li
                      className={`${
                        location?.pathname === "/vehicle" ? "active" : ""
                      }`}
                    >
                      <Link to="/vehicle">
                        <FontAwesomeIcon icon={faTruck} />{" "}
                        {nav_width === "expanded" ? "Véhicules" : null}
                      </Link>
                    </li>
                  ) : (
                    <li
                      className={`${
                        location?.pathname === "/vehicle" ? "active" : ""
                      }`}
                    >
                      <Link to="/fleet-management">
                        <FontAwesomeIcon icon={faTruck} />{" "}
                        {nav_width === "expanded"
                          ? "Véhicules & plateformes"
                          : null}
                      </Link>
                    </li>
                  )}
                  <li
                    className={`${
                      location?.pathname === "/tour" ? "active" : ""
                    }`}
                  >
                    <Link to="/tour">
                      <FontAwesomeIcon icon={faGlobeEurope} />{" "}
                      {nav_width === "expanded" ? "Tournées" : null}
                    </Link>
                  </li>

                  {config?.can_upload_with_excel && (
                    <li
                      className={`${
                        location?.pathname === "/addfile" ? "active" : ""
                      }`}
                    >
                      <Link to="/addfile">
                        <FontAwesomeIcon icon={faFileImport} />{" "}
                        {nav_width === "expanded" ? "Import" : null}
                      </Link>
                    </li>
                  )}
                  <li
                    className={`${
                      location?.pathname === "/admin" ? "active" : ""
                    }`}
                  >
                    {config?.is_admin && (
                      <Link to="/admin">
                        <FontAwesomeIcon icon={faFileInvoiceDollar} />{" "}
                        {nav_width === "expanded" ? "Factures" : null}
                      </Link>
                    )}
                  </li>
                  <li
                    className={`${
                      location?.pathname === "/restriction" ? "active" : ""
                    }`}
                  >
                    <Link to="/restriction">
                      <FontAwesomeIcon icon={faClipboard} />{" "}
                      {nav_width === "expanded" ? "Réserves" : null}
                    </Link>
                  </li>

                  <li
                    className={`${
                      location?.pathname === "/addvehicle" ? "active" : ""
                    }`}
                  >
                    <Link to="/help">
                      <FontAwesomeIcon icon={faQuestion} />{" "}
                      {nav_width === "expanded" ? "Aide" : null}
                    </Link>
                  </li>
                  <li>
                    <div
                      className={"disconnectWrapper"}
                      onClick={() => {
                        logoutAction();
                      }}
                    >
                      <FontAwesomeIcon icon={faSignInAlt} />{" "}
                      {nav_width === "expanded" ? "Déconnexion" : null}
                    </div>
                  </li>
                </ul>
              </div>
              <div className="brand-logo-fleet">
                <Link to={config?.can_access_dashboard_v2 ? "/" : "/vehicle"}>
                  <img
                    src={config?.logo_url || fleetBrand}
                    alt={"brand-logo"}
                  />
                </Link>
              </div>
            </div>
          </nav>
        ) : (
          <nav
            className={`App-nav ${nav_width !== "expanded" ? "collapsed" : ""}`}
          >
            <div className="App-nav-wrapper">
              <div className="nav-list">
                <ul>
                  <li
                    className={`${
                      location?.pathname === "/login" ? "active" : ""
                    }`}
                  >
                    <Link to="/login">
                      <FontAwesomeIcon icon={faSignInAlt} />{" "}
                      {nav_width === "expanded" ? "Login" : null}
                    </Link>
                  </li>
                  <li
                    className={`${
                      location?.pathname === "/help" ? "active" : ""
                    }`}
                  >
                    <Link to="/help">
                      <FontAwesomeIcon icon={faQuestion} />{" "}
                      {nav_width === "expanded" ? "Aide" : null}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="brand-logo-fleet">
                <Link to={config?.can_access_dashboard_v2 ? "/" : "/vehicle"}>
                  <img
                    src={config?.logo_url || fleetBrand}
                    alt={"fleet-logo"}
                  />
                </Link>
              </div>
            </div>
          </nav>
        )}

        <section
          className={`App-frame ${
            nav_width === "expanded" ? "" : "collapsed"
          } ${checkAuth() ? "" : "full"}`}
        >
          <Container style={{ width: "100%" }} fluid>
            <Row>
              <Col style={{ height: "100%" }}>
                <Switch>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/forgot">
                    <Forgot />
                  </Route>
                  <Route
                    path="/reset-password/:token"
                    component={ResetPassword}
                  />
                  <PrivateRoute path="/tour/:id" component={Tour} />
                  <PrivateRoute path="/tour">
                    <Tour location={location} status={query.get("status")} />
                  </PrivateRoute>
                  <PrivateRoute path="/vehicle">
                    <Vehicle />
                  </PrivateRoute>
                  <Route path="/help">
                    <AddVehicle />
                  </Route>
                  {config?.can_upload_with_excel === true && (
                    <PrivateRoute path="/addfile">
                      <AddFile />
                    </PrivateRoute>
                  )}
                  {config?.fleet_optimization === true && (
                    <PrivateRoute path="/optimization">
                      <Optimization />
                    </PrivateRoute>
                  )}
                  {config?.is_admin && (
                    <PrivateRoute path="/admin">
                      <Admin />
                    </PrivateRoute>
                  )}
                  <PrivateRoute path="/restriction">
                    <Restriction />
                  </PrivateRoute>
                  <PrivateRoute path="/fleet-management">
                    <FleetManagement />
                  </PrivateRoute>
                  {checkAuth() ? (
                    config?.can_access_dashboard_v2 ? (
                      <PrivateRoute path="/">
                        <HomeV2 />
                      </PrivateRoute>
                    ) : (
                      <PrivateRoute path="/vehicle">
                        <Vehicle />
                      </PrivateRoute>
                    )
                  ) : (
                    <Route path="/">
                      <Redirect to="/login" />
                    </Route>
                  )}
                </Switch>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <ToastContainer position="bottom-right" limit={1} />
    </div>
  );
}
