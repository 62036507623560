import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import jwt_decode from "jwt-decode";

import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";

import "../App/App.scss";
import "./Login.css";
import { Redirect, withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { authenticate, setConfiguration } from "../../reducer/Action";
import fleetBrand from "../App/FleetWhite.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      testObject: null,
      errorMessage: "",
      errorStatus: false,
    };
  }

  login = (e) => {
    e.preventDefault();

    const url = new URL(this.props.BASE_URL + "/auth/byCredential");
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let myInit = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
      cache: "no-cache",
    };

    fetch(url, myInit)
      .then((response) => {
        // console.log("POST request @ ",  this.props.BASE_URL+'/auth/byCredential');
        if (response.ok) {
          response.json().then((result) => {
            if (result.status === "Bad credentials") {
              this.setState({
                errorMessage: "Mot de passe ou e-mail incorrect.",
                errorStatus: true,
              });
            } else {
              if (result.token) {
                localStorage.setItem("fleetToken", result.token);
                localStorage.setItem("fleetClientId", result.clientId);
                //decode the token and store it in state
                let decoded = result.token ? jwt_decode(result.token) : null;
                if (decoded) {
                  this.props.setConfiguration({
                    is_admin: decoded?.user?.is_admin === 1 ? true : false,
                    can_upload_with_excel:
                      decoded?.user?.can_upload_with_excel === 1 ? true : false,
                    can_access_dashboard_v2:
                      decoded?.user?.can_access_dashboard_v2 === 1
                        ? true
                        : false,
                    fleet_optimization:
                      decoded?.user?.fleet_optimization === 1 ? true : false,
                    logo_url: decoded?.user?.logo_url,
                  });
                }
                this.setState({
                  email: "",
                  password: "",
                });
              }
            }
          });
        } else if (response.status === 401) {
          response.json().then((result) => {
            // console.log("result", result);

            if (result.status === "Bad credentials") {
              this.setState({
                errorMessage: "Mot de passe ou e-mail incorrect.",
                errorStatus: true,
              });
            }
          });
        } else if (response.status === 400) {
          this.setState({
            errorMessage: "Une erreur s'est produite",
            errorStatus: true,
          });
        } else {
          this.setState({
            errorMessage:
              "Une erreur réseau est survenue, veuillez contacter le support client.",
            errorStatus: true,
          });
        }
      })
      .catch(function (error) {
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });

    //this.props.authenticate("teub");

    // console.log("connected", this.props.isAuthenticated );
  };

  handleEmailChange = (e) => {
    const email = e.target.value;
    this.setState({
      email: email,
    });
  };

  handlePasswordChange = (e) => {
    const password = e.target.value;
    this.setState({
      password: password,
    });
  };

  render() {
    if (checkAuth()) {
      let decoded = jwt_decode(localStorage.getItem("fleetToken"));
      return (
        <Redirect
          to={decoded?.user?.can_access_dashboard_v2 === 1 ? "/" : "/vehicle"}
        />
      );
    }

    return (
      <Row className="align-items-center h-100">
        <Col lg={6} className="d-flex align-items-center">
          <div className="container-form">
            <h2 className="welcome">Bienvenue</h2>
            <Form>
              <div className={"error-message-div"}>
                <div
                  style={{
                    display: this.state.errorStatus ? "block" : "none",
                  }}
                  className={"error-msg-login"}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                  {this.state.errorMessage}
                </div>
              </div>
              <Form.Label className="label">Adresse email</Form.Label>
              <Form.Control
                className="custom-input-login"
                type="email"
                placeholder="Votre adresse email"
                autoComplete="username"
                onChange={(e) => this.handleEmailChange(e)}
                defaultValue={this.state.email}
                aria-describedby="basic-addon1"
              />
              <Form.Label className="label-2">Mot de passe</Form.Label>
              <Form.Control
                className="custom-input-login"
                type="password"
                placeholder="Votre mot de passe"
                autoComplete="current-password"
                onChange={(e) => this.handlePasswordChange(e)}
                defaultValue={this.state.password}
                aria-describedby="basic-addon2"
              />

              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: "100%" }}
              >
                <Link to="/forgot">
                  <p className="password-forget mt-4 mb-0">
                    Mot de passe oublié ?
                  </p>
                </Link>
                <Button
                  variant=""
                  type="submit"
                  onClick={(e) => this.login(e)}
                  className={"btn-login mt-4"}
                  block="true"
                  disabled={
                    this.state.email === "" || this.state.password === ""
                  }
                >
                  Connexion
                </Button>
              </div>
            </Form>
          </div>
        </Col>
        <Col
          lg={6}
          className="h-100 d-none d-sm-none d-lg-block p-relative background-img-container"
          style={{ backgroundColor: "#079992" }}
        >
          <div className="background-img d-flex align-items-center justify-content-center">
            <img src={fleetBrand} alt="logo" className="logo-login" />
          </div>
        </Col>
      </Row>
    );
  }
}

const checkAuth = () => {
  return localStorage.getItem("fleetToken");
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    BASE_URL: state.BASE_URL,
    config: state.config,
  };
};
const mapDispatchToProps = { authenticate, setConfiguration };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
