import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faInfoCircle, faKey } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import "../App/App.scss";
import "./Reset-password.css";
import { connect } from "react-redux";
import fleetBrand from "../App/FleetWhite.png";

export class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: "",
			password: "",
			passwordConfirm: "",
			tokenErrorMessage: "",
			informationMessage: "",
			errorMessage: "",
			errorStatus: false
		};
	}

	async componentDidMount() {
		const { token } = this?.props?.match?.params;

		if (token) {
			// On va vérifier la validité du token en appelant une route côté back

			this.setState({
				token: token
			});

			const url = new URL(this?.props?.BASE_URL + "/auth/check-forgot-password-token-validity");
			let myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			let myInit = {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					token: token
				}),
				cache: "no-cache"
			};

			let response = await fetch(url, myInit);
			let result = await response.json();

			if (!response.ok) {
				this.setState({
					tokenErrorMessage: result.error
				});
			}
		} else {
			this.setState({
				tokenErrorMessage: "Le token ne semble plus être valide. Veuillez refaire la demande de réinitialisation de mot de passe !"
			});
		}
	}

	changePassword = async (e) => {
		e.preventDefault();

		if (this?.state?.password && this?.state?.passwordConfirm && this?.state?.password === this?.state?.passwordConfirm && this?.state?.token) {
			const url = new URL(this.props.BASE_URL + "/auth/change-password");
			let myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			let myInit = {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					token: this.state.token,
					password: this.state.password
				}),
				cache: "no-cache"
			};

			let response = await fetch(url, myInit);
			let result = await response.json();

			if (response.ok) {
				this.setState({
					password: "",
					passwordConfirm: "",
					informationMessage: result?.message,
					errorMessage: "",
					errorStatus: false
				});
			} else {
				this.setState({
					informationMessage: "",
					errorMessage: "Une erreur s'est produite",
					errorStatus: true
				});
			}
		}
	};

	componentDidUpdate() {
		console.log("States :", this.state.password, this.state.passwordConfirm);
	}

	handlePasswordChange = (e) => {
		this.setState({
			password: e.target.value
		});
	};

	handlePasswordConfirmChange = (e) => {
		this.setState({
			passwordConfirm: e.target.value
		});
	};

	render() {
		return (
			<>
				<div style={{ height: '100%' }}>
					<Row className="align-items-center h-100">
						<Col lg={6} className="d-flex align-items-center">
							{this.state.tokenErrorMessage ? (
								<>
									<div className={"center-block"}>
										<div className={"token-error-message"}>{this.state.tokenErrorMessage}</div>
										<Link to="/login">
											<p>Retour à la page de connexion</p>
										</Link>
									</div>
								</>
							) : this.state.token ? (
								<>
									<div className="container-form">
										<h2 className="welcome">Réinitialisez votre mot de passe</h2>
										<p className="p-info">Saisissez votre nouveau mot de passe ci-dessous.</p>
										<Form>
											<Form.Label className="label">Mot de passe</Form.Label>
											<Form.Control
												className="custom-input-reset"
												placeholder="Votre nouveau mot de passe"
												type="password"
												onChange={(e) => this.handlePasswordChange(e)}
												aria-describedby="basic-addon1"
											/>
											<Form.Control
												className="custom-input-reset mt-3"
												placeholder="Confirmation du mot de passe"
												type="password"
												onChange={(e) => this.handlePasswordConfirmChange(e)}
												aria-describedby="basic-addon1"
											/>
											<div style={{ width: '100%' }}>
												<Button
													disabled={this.state.password && this.state.passwordConfirm && this.state.password === this.state.passwordConfirm ? false : true}
													variant="none"
													type="submit"
													onClick={async (e) => await this.changePassword(e)}
													block
													className={"change-password-btn mt-4"}
												>
													Changer de mot de passe
												</Button>
											</div>
										</Form>
									</div>
									{this.state.informationMessage ? (
										<>
											<div className={"information-message-container"}>
												<div className={"information-message"}>
													<FontAwesomeIcon icon={faInfoCircle} /> {this.state.informationMessage}
												</div>
											</div>
										</>
									) : null}
									<div className={"error-message-container"}>
										<div
											style={{
												display: this.state.errorStatus ? "block" : "none"
											}}
											className={"error-message"}
										>
											<FontAwesomeIcon icon={faExclamationCircle} /> {this.state.errorMessage}
										</div>
									</div>
								</>
							) : null}
						</Col>
						<Col lg={6} className="h-100 d-sm-none d-lg-block" style={{ backgroundColor: '#079992' }}>
							<div className="background-img d-flex align-items-center justify-content-center">
								<img src={fleetBrand} alt="logo" className="logo-login" />
							</div>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state /*, ownProps*/) => {
	return {
		BASE_URL: state.BASE_URL
	};
};

export default connect(mapStateToProps)(ResetPassword);
