import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "../../../App/App.scss";
import "./StatusComponent.css";

export class StatusComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusColorClass: null,
      statusText: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.computeStatus();
    }
  }

  componentDidMount() {
    this.computeStatus();
  }

  computeStatus() {
    let newStatusText = "";
    let newColorClass = "";

    switch (this.props.status) {
      case "CREATED":
        newStatusText = "Créée";
        newColorClass = "ok";
        break;
      case "STARTED":
        newStatusText = "Débutée";
        newColorClass = "ok";
        break;
      case "DONE":
        newStatusText = "Terminée";
        newColorClass = "ok";
        break;
      case "LATE":
        newStatusText = "En retard";
        newColorClass = "late";
        break;
      case "ISSUE":
        newStatusText = "Problème";
        newColorClass = "issue";
        break;
      default:
        newStatusText = "default";
        newColorClass = "issue";
    }

    if (this.props.isLate) {
      this.setState({
        statusText: newStatusText,
        statusColorClass: "late",
      });
    } else {
      this.setState({
        statusText: newStatusText,
        statusColorClass: newColorClass,
      });
    }
  }

  render() {
    return (
      <div className={"fleet-status-component-wrapper"}>
        {false && this.state.statusColorClass === "late" ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip-2">En retard</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <div className="status-wrapper" {...triggerHandler} ref={ref}>
                <span
                  className={"status-background " + this.state.statusColorClass}
                ></span>
                <span
                  className={"status-bullet " + this.state.statusColorClass}
                ></span>
              </div>
            )}
          </OverlayTrigger>
        ) : (
          <div className="status-wrapper">
            <span
              className={"status-background " + this.state.statusColorClass}
            ></span>
            <span
              className={"status-bullet " + this.state.statusColorClass}
            ></span>
          </div>
        )}

        <span className={"status-text"}>{this.state.statusText}</span>
      </div>
    );
  }
}

export default StatusComponent;
