import React, { Component } from "react";

import { Accordion } from "react-bootstrap";
import deferComponentRender from "../deferComponentRender";

import "../App/App.scss";
import Step from "../Step/Step";
import { MyStepLoader } from "../Loader/MyLoader";

function AccccordionSteps(props) {
  return (
    <Accordion>
      {props.steps?.map((value, index) => {
        return (
          <Step
            key={`step_key_${value.step_id}`}
            step={value}
            stepsNumber={value.order}
            bls={value.bls || []}
            exactMatch={value.exactMatch}
          />
        );
      })}
    </Accordion>
  );
}

function StepList(props) {
  const DeferredTimeline = deferComponentRender(AccccordionSteps);

  if (props.steps.length > 0) {
    return <AccccordionSteps {...props} />;
  } else {
    if (props.isLoading) {
      return <MyStepLoader />;
    } else {
      return (
        <div className={"fleet-item-step-no-item"}>
          <p>Aucune étape n'est associée à cette tournée.</p>
        </div>
      );
    }
  }
}

export default React.memo(StepList);
