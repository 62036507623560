export const reserve_status = {
  CREATED: "CREATED",
  SOLVED: "SOLVED",
  properties: {
    CREATED: {
      display_name: "A traiter",
      color: "#FA983A",
    },
    SOLVED: {
      display_name: "Traitée",
      color: "#6B6B6B",
    },
  },
};
