import React, { Component, forwardRef } from "react";
import { Col } from "react-bootstrap";
import fr from "date-fns/locale/fr";

import "react-perfect-scrollbar/dist/css/styles.css";
import in_out from "./fleetBook_light.png";
import in_out_disable from "./fleetBook_light_disabled.png";
import FleetLoader from "../../GenericComponent/FleetLoader/FleetLoader";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

registerLocale("fr", fr);
setDefaultLocale("fr");

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <button
    variant=""
    className="dropdown-filter-button dropdown-toggle btn fleet-item-generic-button mb-2"
    onClick={onClick}
    ref={ref}
  >
    {value}
  </button>
));

export class ResumeBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentSize: props.componentSize,
      sumCount: 0,
      isResumeAvailable: true,
      isLoading: false,
      error: "",
      date: new Date(),
    };
  }

  fetchData = () => {
    let tours = [];

    let url = new URL(this.props.BASE_URL + "/tours/full");
    const token = localStorage.getItem("fleetToken");

    if (this.state.date) {
      const new_date = moment(this.state.date).format("YYYY-MM-DD");
      url = new URL(
        this.props.BASE_URL +
          "/tours/full?start_date=" +
          new_date +
          "&end_date=" +
          new_date
      );
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = {
      method: "GET",
      headers: myHeaders,
      cache: "no-cache",
    };

    console.log("GET request @ ", this.props.BASE_URL + "/tours/full");
    return fetch(url, myInit)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === "Success") {
          tours = result.tours || [];
          return tours;
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: "Problème lors de la récuperation des tournées.",
        });
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  };

  fetchDataServerSidedResume = () => {
    let url = new URL(this.props.BASE_URL + "/document/resumeForDate");
    const token = localStorage.getItem("fleetToken");

    if (this.state.date) {
      const new_date = moment(this.state.date).format("YYYY-MM-DD");
      url = new URL(
        this.props.BASE_URL +
          "/document/resumeForDate?start_date=" +
          new_date +
          "&end_date=" +
          new_date
      );
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    let myInit = {
      method: "GET",
      headers: myHeaders,
      cache: "no-cache",
    };

    console.log(
      "GET request @ ",
      this.props.BASE_URL + "/document/resumeForDate"
    );
    return fetch(url, myInit)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === "Success" && result.url) {
          this.setState({
            error: "",
          });
          return result.url;
        } else {
          this.setState({
            error: "Pas de tournée pour ce jour.",
          });
          return null;
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: "Problème lors de la récuperation des tournées.",
        });
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  };

  downloadResume = async (e) => {
    this.setState({
      isLoading: true,
    });

    let data_resume = await this.fetchDataServerSidedResume();

    if (data_resume) {
      window.open(data_resume, "_blank");
    }

    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 1000);
  };

  setStartDate = (date) => {
    this.setState({
      date: date,
    });
  };

  render() {
    return (
      <Col xs={this.state.componentSize}>
        <div className="card-fleet" style={{ overflow: "initial" }}>
          <h4>Résumé</h4>
          <div className={"fleet-resume-container"}>
            <div
              className={"fleet-resume no-resume"}
              style={{
                display: this.state.isResumeAvailable ? "none" : "flex",
              }}
            >
              <img
                src={in_out_disable}
                className={"no-resume-img"}
                alt={"no-resume-available"}
              />
              <h5>Résumé indisponible</h5>
              <p>
                Des tournées sont encore en cours, revenez à la fin pour obtenir
                le resumé de la journée.
              </p>
            </div>
            <div
              className={"fleet-resume get-resume"}
              id="testt"
              style={{
                display: !this.state.isResumeAvailable ? "none" : "flex",
              }}
            >
              <img
                src={in_out}
                className={"no-resume-img"}
                alt={"no-resume-available"}
              />
              <h5>Résumé disponible</h5>
              <p>Le résumé des tournées de la journée est disponible.</p>
              <DatePicker
                selected={this.state.date}
                onChange={(date) => this.setStartDate(date)}
                customInput={<ExampleCustomInput />}
                locale="fr"
                dateFormat="dd/MM/yyyy"
              />

              <div>
                <button
                  className={"btn fleet-item-generic-button"}
                  onClick={(e) => this.downloadResume(e)}
                  disabled={this.state.isLoading}
                >
                  Télécharger
                </button>
              </div>
              <div className={"error-message-container-reserve"}>
                <div
                  className={"error-message-reserve"}
                  style={{
                    display: this.state.error !== "" ? "block" : "none",
                  }}
                >
                  {this.state.error}
                </div>
              </div>
            </div>
          </div>
          <FleetLoader isLoading={this.state.isLoading} />
        </div>
      </Col>
    );
  }
}

export default ResumeBoard;
