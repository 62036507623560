import React, { Component } from "react";
import { Col, Table } from "react-bootstrap";

import "./FacturationBoard.css";

export class FacturationBoard extends Component {
  constructor(props) {
    super(props);

    //convert date string to date object
    if (props.facturations.length > 0) {
      props.facturations.map((value, index) => {
        const factDate = new Date(value.date);
        props.facturations[index].date = factDate;
      });
    }

    this.state = {
      componentSize: props.componentSize,
      facturations: props.facturations,
    };
  }

  render() {
    return (
      <Col xs={this.state.componentSize}>
        <div className="card-fleet">
          <h4>Véhicules actif par mois</h4>

          <div className={"card-fleet-header-2"}>
            <span className={"card-fleet-header-2-title"}>Mois en cours</span>
          </div>

          <div className={"issues-return-container-wrapper"}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <td>Mois</td>
                  <td>Camions</td>
                  <td>Prix</td>
                </tr>
              </thead>
              <tbody>
                {this.state.facturations.map((value, index) => {
                  const now = new Date();
                  if (value.date.getMonth() === now.getMonth()) {
                    return (
                      <tr>
                        <td>
                          {value.date.toLocaleDateString("fr-FR", {
                            month: "short",
                            year: "numeric",
                          })}
                        </td>
                        <td>{value.truckCount}</td>
                        <td>
                          {value.price}€ <span className="devise">TTC</span>
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </Table>
          </div>

          <div className={"card-fleet-header-2"}>
            <span className={"card-fleet-header-2-title"}>Mois écoulés</span>
          </div>

          <div className={"issues-return-container-wrapper"}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <td>Mois</td>
                  <td>Camions</td>
                  <td>Prix</td>
                </tr>
              </thead>
              <tbody>
                {this.state.facturations.map((value, index) => {
                  const now = new Date();
                  if (value.date.getMonth() !== now.getMonth()) {
                    return (
                      <tr className={"table-facturation"}>
                        <td>
                          {value.date.toLocaleDateString("fr-FR", {
                            month: "short",
                            year: "numeric",
                          })}
                        </td>
                        <td>{value.truckCount}</td>
                        <td>
                          {value.price}€ <span className="devise">TTC</span>
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </Col>
    );
  }
}

export default FacturationBoard;
