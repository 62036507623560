import React, { Component, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { AccordionContext } from "react-bootstrap";

import { Card, Badge, Accordion, useAccordionButton } from "react-bootstrap";

import "../App/App.scss";
import "./StepOptimization.scss";
import box from "./box.png";
import inImg from "./StepAssets/in.png";
import outImg from "./StepAssets/out.png";
import inOutImg from "./StepAssets/in_out.png";

import { setBlsForATour } from "../../reducer/Action";
import connect from "react-redux/es/connect/connect";
export class StepOptimization extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      detailed: false,
      statusColorClass: "",
      isLoading: false,
      localBls: null,
    };
  }
  static contextType = AccordionContext;

  render() {
    let imgComponent = null;

    if (this.props?.bls) {
      let inValue = false;
      let outValue = false;

      this.props?.bls?.map((value) => {
        if (value.delivery_type != null) {
          if (value.delivery_type === "OUT") {
            outValue = true;
          }
          if (value.delivery_type === "IN") {
            inValue = true;
          }
        }
      });

      if (inValue && !outValue) {
        imgComponent = (
          <img
            src={inImg}
            className={"img-illustration-fleet-step"}
            alt={"truck"}
          />
        );
      } else if (!inValue && outValue) {
        imgComponent = (
          <img
            src={outImg}
            className={"img-illustration-fleet-step"}
            alt={"truck"}
          />
        );
      } else if (inValue && outValue) {
        imgComponent = (
          <img
            src={inOutImg}
            className={"img-illustration-fleet-step"}
            alt={"truck"}
          />
        );
      } else {
        imgComponent = (
          <img
            src={outImg}
            className={"img-illustration-fleet-step"}
            alt={"truck"}
          />
        );
      }
    } else {
      imgComponent = (
        <img src={outImg} className={"img-illustration-fleet"} alt={"truck"} />
      );
    }

    return (
      <div className="fleet-item-step-optimization-container">
        <span
          className="fleet-step-opt-nb"
          style={{ backgroundColor: this.props.color }}
        >
          {this.props.stepsNumber}
        </span>
        <div className={"fleet-item-step-optimization"}>
          <div className={"fleet-first-line"}>
            <div className={"hw"}>{imgComponent}</div>
            <div className={"fr"}>
              <span className={"fleet-item-title"}>
                {this.props.step.name || "Étape invalide"}{" "}
              </span>
              <span className={"fleet-item-desc"}>
                {this.props.step.address} {this.props.step.city},{" "}
                {this.props.step.zipcode}
              </span>
            </div>
            <div className={"hwe"}>
              <div className="hwe-child-wrapper">
                <span className="truck-capacity">
                  {this.props.step.bls_volume}L
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    BASE_URL: state.BASE_URL,
  };
};

const mapDispatchToProps = { setBlsForATour };

export default connect(mapStateToProps, mapDispatchToProps)(StepOptimization);
