import React, { Component } from "react";
import { ButtonGroup, Button } from "react-bootstrap";

export class ClientFilters extends Component {
	constructor(props) {
		super(props);
		this.state = {
			validClients: [],
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.invoicesPerClients !== this.props.invoicesPerClients) {
			this.setState({
				validClients: this.props.invoicesPerClients.filter(client => {
					return client.invoices.length > 0
				})
			})
		}
	}
	
	render() {
		return this.state.validClients.length > 1
			? (<>
					<ButtonGroup aria-label="Client select">
						{
							this.state.validClients?.map(client => 
								<Button variant="" onClick={() => this.props.selectInvoicesForClientId(client.client_id)}>
									{ client?.client_name }
								</Button>
								)
						}
					</ButtonGroup>
				</>)
			: null
	}
}