import React from 'react';
import "./platform-color-badge.scss";

const BadgeColor = (props) => {
  return (
    <div style={{backgroundColor: props.color +"44"}} className='badge-background'>
      <div style={{color: props.color }} className='badge-text'>
        PLATEFORME N° {props.platform || "platform_id"}
      </div>
    </div>
  );
}

export default BadgeColor;
