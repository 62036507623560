import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";

import "./item.css";

export class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: props.activeKey,
      number: props.number,
      products: props.products,
    };
  }

  render() {
    return (
      <div className={"fleet-item-bl-card-ter"}>
        <div className={"fleet-item-bl-wrapper"}>
          <div className={"fleet-item-bl-number-wrap"}>
            <div>
              <FontAwesomeIcon icon={faLeaf} style={{ color: "#079992" }} />{" "}
              <span className={"fleet-item-bl-card-ter-use"}>
                {" "}
                {this.props.item.item_key
                  ? this.props.item.item_key
                  : "id indisponible"}{" "}
                -{" "}
                {this.props.item.name
                  ? this.props.item.name
                  : "Titre indisponible"}{" "}
              </span>
            </div>
            <div>
              <span className={"fleet-item-bl-card-ter-use"}>
                {" "}
                x
                {this.props.item.quantity
                  ? this.props.item.quantity
                  : "qtt introuvable"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Item;
